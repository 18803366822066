export const ADD_PREVIEW_LAYER_ID = 'ADD_PREVIEW_LAYER_ID'
export const REMOVE_PREVIEW_LAYER_ID = 'REMOVE_PREVIEW_LAYER_ID'
export const SET_SHOW_ADVISORIES = 'SET_SHOW_ADVISORIES'
export const SET_SHOW_RULES_MODAL = 'SET_SHOW_RULES_MODAL'
export const RECEIVE_FLIGHT_PLAN = 'RECEIVE_FLIGHT_PLAN'
export const SET_SHOW_PROFILE_MODAL = 'SET_SHOW_PROFILE_MODAL'
export const SET_SHOW_AIRCRAFT_MODAL = 'SET_SHOW_AIRCRAFT_MODAL'
export const SET_OPEN_VERIFICATION_REQUIRED_ALERT = 'SET_OPEN_VERIFICATION_REQUIRED_ALERT'
export const SET_OPEN_BRIEF_MODAL = 'SET_OPEN_BRIEF_MODAL'
export const SET_OPEN_WHY_WERE_ASKING_MODAL = 'SET_OPEN_WHY_WERE_ASKING_MODAL'
export const SET_RULE_DETAILS = 'SET_RULE_DETAILS'
export const SET_REQUEST_SUBMIT_FLIGHT_PLAN = 'SET_REQUEST_SUBMIT_FLIGHT_PLAN'
export const SET_SUBMIT_FLIGHT_PLAN_ERROR = 'SET_SUBMIT_FLIGHT_PLAN_ERROR'
export const SET_SUBMIT_FLIGHT_PLAN_SUCCESS = 'SET_SUBMIT_FLIGHT_PLAN_SUCCESS'
export const SET_FLIGHT_PLAN_OVERLAP_ERROR = 'SET_FLIGHT_PLAN_OVERLAP_ERROR'
export const SET_FLIGHT_ADVISORIES = 'SET_FLIGHT_ADVISORIES'
export const CLEAR_FLIGHT_PLANNING_VALUES = 'CLEAR_FLIGHT_PLANNING_VALUES'
export const ADD_SELECTED_CONFLICTING_FLIGHT = 'ADD_SELECTED_CONFLICTING_FLIGHT'
export const REMOVE_SELECTED_CONFLICTING_FLIGHT = 'REMOVE_SELECTED_CONFLICTING_FLIGHT'
export const SET_CONFLICTING_FLIGHTS = 'SET_CONFLICTING_FLIGHTS'
export const SET_OWN_CONFLICTING_FLIGHTS = 'SET_OWN_CONFLICTING_FLIGHTS'
export const SET_SHOW_UPLOAD_GEOJSON_MODAL = 'SET_SHOW_UPLOAD_GEOJSON_MODAL'
export const SET_UPLOADING_GEOJSON = 'SET_UPLOADING_GEOJSON'
export const SET_FLIGHT_PLAN_ID = 'SET_FLIGHT_PLAN_ID'
export const SET_FETCH_EXISTING_FLIGHT_PLAN_IN_PROGRESS = 'SET_FETCH_EXISTING_FLIGHT_PLAN_IN_PROGRESS'
export const SET_REQUESTING_FLIGHT_PLAN = 'SET_REQUESTING_FLIGHT_PLAN'
export const RECEIVE_INACTIVE_FLIGHT_ADVISORIES = 'RECEIVE_INACTIVE_FLIGHT_ADVISORIES'
