import { createRulesPluginContainer } from 'modules/rules-plugin/RulesPlugin.container'

const reducerId = 'rulesPluginHome'

const {
  rulesPluginStore,
  rulesPluginSelectors,
  rulesPluginActions,
  RulesPluginContainer
} = createRulesPluginContainer([reducerId])

export {
  rulesPluginStore as rulesPluginStoreHome,
  rulesPluginSelectors as rulesPluginSelectorsHome,
  rulesPluginActions as rulesPluginActionsHome,
  reducerId as rulesHomeReducerId,
  RulesPluginContainer as RulesPluginContainerHome
}
