import config from '../config/general'

const { apiHost, appEnv } = config

type BuildUrlParams = {
  service: string,
  protocol: string | null,
  path: string | null,
  prependAppEnv?: boolean
}

export type BuildApiParams = {
  url: string
  envVar: string
  defaultPath: string
}

function buildServicesUrl({ serviceUrl, protocol, path, prependAppEnv = true }: { serviceUrl: string, protocol: string | null, path: string | null, prependAppEnv: boolean}) {
  if (prependAppEnv && appEnv !== 'prod') {
    serviceUrl = `${appEnv}.${serviceUrl}`
  }

  if (protocol) {
    serviceUrl = `${protocol}://${serviceUrl}`
  }

  if (path) {
    serviceUrl = `${serviceUrl}/${path}`
  }
  return serviceUrl
}

export function buildServiceUrl({ service, protocol, path, prependAppEnv = true }: BuildUrlParams) {
  const serviceUrl = `${service}.${apiHost}`
  return buildServicesUrl({ serviceUrl, protocol, path, prependAppEnv })
}

export function buildAuthUrl({ service, protocol, path, prependAppEnv = true }: BuildUrlParams) {
  const serviceUrl = `${service}.${apiHost}`
  return buildServicesUrl({ serviceUrl, protocol, path, prependAppEnv })
}

export function buildApiUrl({ url, envVar, defaultPath }: BuildApiParams) {
  const debuggingUrl = import.meta.env[`DEBUGGING_${envVar}`]
  const isDebuggingAPI = Boolean(debuggingUrl)

  if (isDebuggingAPI) {
    return debuggingUrl
  }

  const path = import.meta.env[envVar] || defaultPath

  return url + path
}
