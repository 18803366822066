import enTranslationMessages from './en.json'
import esTranslationMessages from './es.json'
import jaTranslationMessages from './ja.json'
import deTranslationMessages from './de.json'
import frTranslationMessages from './fr.json'
import itTranslationMessages from './it.json'
import frChTranslationMessages from './fr-ch.json'
import ptPtTranslationMessages from './pt-pt.json'
import skyguideTranslations from "./skyguide"
import { PHRASE_TRANSLATIONS_PROJECT_NAME } from '@env'

// The translations for a language
type LanguageTranslations = {[key: string]: string}

// The translations for a white label, with all available languages
type WhiteLabelTranslations = {[key: string]: LanguageTranslations}

// All white label translations
type AllTranslations = {[key: string]: WhiteLabelTranslations}

const whiteLabelTranslations: AllTranslations = {
  "skyguide": skyguideTranslations
}


export const translationMessages: WhiteLabelTranslations = {
  en: enTranslationMessages,
  es: esTranslationMessages,
  ja: jaTranslationMessages,
  de: deTranslationMessages,
  fr: frTranslationMessages,
  it: itTranslationMessages,
  'fr-ch': frChTranslationMessages,
  'pt-pt': ptPtTranslationMessages
}

export const languageCodes = {
  en: 'en',
  es: 'es',
  ja: 'ja',
  de: 'de',
  fr: 'fr',
  it: 'it',
  frCH: 'fr-ch',
  ptPT: 'pt-pt'
}

export const translationOptions = {
  en: { value: languageCodes.en, label: 'English' },
  es: { value: languageCodes.es, label: 'Español' },
  ja: { value: languageCodes.ja, label: 'Japanese' },
  de: { value: languageCodes.de, label: 'Deutsch' },
  fr: { value: languageCodes.fr, label: 'Français (France)' },
  it: { value: languageCodes.it, label: 'Italiano' },
  'fr-ch': { value: languageCodes.frCH, label: 'Français (Suisse)' },
  'pt-pt': { value: languageCodes.ptPT, label: 'Português' }
}

export const registryAvailableTranslations = [languageCodes.en, languageCodes.ptPT]
export const sfoAvailableTranslations = [languageCodes.en, languageCodes.fr, languageCodes.frCH, languageCodes.de]
export const webappAvailableTranslations = [
  languageCodes.en,
  languageCodes.fr,
  languageCodes.frCH,
  languageCodes.de,
  languageCodes.ptPT
]

export const customTranslationMessages: WhiteLabelTranslations = PHRASE_TRANSLATIONS_PROJECT_NAME in whiteLabelTranslations 
  ? whiteLabelTranslations[PHRASE_TRANSLATIONS_PROJECT_NAME]
  : {}
