import { appVersion } from '../../../app-version.ts'
import { airmapWebAppWhiteLabelConfig } from '../../airmap/web-app/airmap.web-app.config.ts'
import { RakutenPrivacyPolicy } from '../shared/rakuten.privacy-policy'

export const rakutenWebAppWhiteLabelConfig = {
  ...airmapWebAppWhiteLabelConfig,
  Settings: {
    ...airmapWebAppWhiteLabelConfig.Settings,
    SocialMedia: {
      Text: 'Follow @AirMapIO',
      Link: 'https://twitter.com/AirMapIO'
    },
    AppVersion: appVersion,
    Faq: {
      Text: 'FAQ',
      Link: 'https://support.airmap.com/hc/en-us'
    },
    PrivacyPolicy: {
      Text: 'Privacy Policy',
      Link: 'https://www.airmap.com/privacy-policy/'
    },
    TermsAndConditions: {
      Text: 'Terms and Conditions',
      Link: 'https://www.airmap.com/terms-service/'
    }
  },
  PolicyContent: RakutenPrivacyPolicy,
  MapSettings: {
    ...airmapWebAppWhiteLabelConfig.MapSettings,
    defaultLocation: {
      ...airmapWebAppWhiteLabelConfig.MapSettings.defaultLocation,
      latitude: 34.017931,
      longitude: -118.496046
    }
  },
  showToCAgreementModal: true
}
