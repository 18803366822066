export const SET_BUFFER_VALUE = 'SET_BUFFER_VALUE'
export const SET_DRAW_MAP_LOADED = 'SET_DRAW_MAP_LOADED'
export const REQUEST_DELETE_DRAWING = 'REQUEST_DELETE_DRAWING'
export const SET_DRAW_MODE = 'SET_DRAW_MODE'
export const SET_CENTER_COORDINATES = 'SET_CENTER_COORDINATES'
export const SET_FLIGHT_PLAN_LAYER_ID = 'SET_FLIGHT_PLAN_LAYER_ID'
export const ADD_NON_BUFFERED_FEATURE = 'ADD_NON_BUFFERED_FEATURE'
export const DELETE_FLIGHT_PLAN_GEOMETRY = 'DELETE_FLIGHT_PLAN_GEOMETRY'
export const DELETE_FLIGHT_PLAN_LAYER_ID = 'DELETE_FLIGHT_PLAN_LAYER_ID'
export const SET_FLIGHT_PLAN_COLOR = 'SET_FLIGHT_PLAN_COLOR'
export const ADD_NEW_FLIGHT_PLAN_GEOMETRY = 'ADD_NEW_FLIGHT_PLAN_GEOMETRY'
export const SET_FLIGHT_PLAN_ERROR = 'SET_FLIGHT_PLAN_ERROR'
export const SET_REQUEST_DELETE_LOCAL_STORAGE = 'SET_REQUEST_DELETE_LOCAL_STORAGE'
export const CLEAR_ALL_DRAWING_VALUES = 'CLEAR_ALL_DRAWING_VALUES'
export const MANUALLY_SET_DRAW_MODE = 'MANUALLY_SET_DRAW_MODE'
export const DRAW_MODE_SET_MANUALLY = 'DRAW_MODE_SET_MANUALLY'
export const CANCEL_REQUEST_DELETE_DRAWING = 'CANCEL_REQUEST_DELETE_DRAWING'
export const CONFIRM_REQUEST_DELETE_DRAWING = 'CONFIRM_REQUEST_DELETE_DRAWING'
export const CLEAR_REQUEST_DELETE_DRAWING = 'CLEAR_REQUEST_DELETE_DRAWING'
export const REQUEST_DELETE_CONFIRMATION_DIALOG = 'REQUEST_DELETE_CONFIRMATION_DIALOG'
export const SET_REQUESTING_RE_CENTER_DRAWING = 'SET_REQUESTING_RE_CENTER_DRAWING'
