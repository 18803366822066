export const SET_ADD_AIRCRAFT_MODE = 'SET_ADD_AIRCRAFT_MODE'
export const RECEIVE_AIRCRAFT_MANUFACTURERS = 'RECEIVE_AIRCRAFT_MANUFACTURERS'
export const RECEIVE_AIRCRAFT_MODELS = 'RECEIVE_AIRCRAFT_MODELS'
export const REMOVE_AIRCRAFT_DATA = 'REMOVE_AIRCRAFT_DATA'
export const RECEIVE_AIRCRAFT_DATA = 'RECEIVE_AIRCRAFT_DATA'
export const UPDATE_AIRCRAFT_NAME = 'UPDATE_AIRCRAFT_NAME'
export const SET_SELECTED_AIRCRAFT = 'SET_SELECTED_AIRCRAFT'
export const SET_NEW_AIRCRAFT_NAME = 'SET_NEW_AIRCRAFT_NAME'
export const SET_MANUFACTURER_VALUE = 'SET_MANUFACTURER_VALUE'
export const SET_MODEL_VALUE = 'SET_MODEL_VALUE'
export const CLEAR_AIRCRAFT_MODAL_VALUES = 'CLEAR_AIRCRAFT_MODAL_VALUES'
export const SET_UPDATE_AIRCRAFT_LIST_SUCCESS = 'SET_UPDATE_AIRCRAFT_LIST_SUCCESS'
export const SET_SHOW_REMOVE_AIRCRAFT_DIALOG = 'SET_SHOW_REMOVE_AIRCRAFT_DIALOG'
export const SET_REMOVE_AIRCRAFT_SUCCESS = 'SET_REMOVE_AIRCRAFT_SUCCESS'
export const SET_ADD_AIRCRAFT_SUCCESS = 'SET_ADD_AIRCRAFT_SUCCESS'
