import * as types from './action-types'

export const initialState = {
  preFlightMode: false,
  inFlightMode: false,
  upcomingFlight: {},
  upcomingFlightAdvisories: {},
  upcomingFlightAdvisoryCount: 0,
  upcomingFlightAdvisoriesLastUpdated: '',
  showUpcomingFlightAdvisories: false,
  openUpcomingFlightBriefModal: false,
  requestingEndFlight: false,
  endFlightSuccess: false,
  endFlightError: false,
  requestingCancelFlight: false,
  cancelFlightSuccess: false,
  cancelFlightError: false,
  requestPurgeFlight: false,
  showEndFlightDialog: false,
  trafficClient: null,
  SAmessages: [],
  alertMessages: [],
  selectedTraffic: '',
  snackbarMessageData: {}
}

export function flightModeReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_PRE_FLIGHT_MODE:
      return {
        ...state,
        preFlightMode: action.setting
      }
    case types.SET_IN_FLIGHT_MODE:
      return {
        ...state,
        inFlightMode: action.setting
      }
    case types.SET_SNACKBAR_MESSAGE_DATA:
      return {
        ...state,
        snackbarMessageData: action.data
      }
    case types.RECEIVE_UPCOMING_FLIGHT:
      return {
        ...state,
        upcomingFlight: action.flight
      }
    case types.CLEAR_UPCOMING_FLIGHT:
      return {
        ...state,
        upcomingFlight: initialState.upcomingFlight
      }
    case types.RECEIVE_UPCOMING_FLIGHT_ADVISORIES:
      return {
        ...state,
        upcomingFlightAdvisories: action.advisories,
        upcomingFlightAdvisoryCount: action.advisoryCount,
        upcomingFlightAdvisoriesLastUpdated: action.lastUpdated
      }
    case types.SET_SHOW_UPCOMING_FLIGHT_ADVISORIES:
      return {
        ...state,
        showUpcomingFlightAdvisories: action.setting
      }
    case types.SET_OPEN_UPCOMING_FLIGHT_BRIEF_MODAL:
      return {
        ...state,
        openUpcomingFlightBriefModal: action.setting
      }
    case types.SET_REQUESTING_END_FLIGHT:
      return {
        ...state,
        requestingEndFlight: action.setting
      }
    case types.SET_END_FLIGHT_SUCCESS:
      return {
        ...state,
        endFlightSuccess: action.setting
      }
    case types.SET_END_FLIGHT_ERROR:
      return {
        ...state,
        endFlightError: action.setting
      }
    case types.CLEAR_END_FLIGHT_DIALOG:
      return {
        ...state,
        endFlightError: false,
        endFlightSuccess: false,
        requestingEndFlight: false
      }
    case types.SET_REQUESTING_CANCEL_FLIGHT:
      return {
        ...state,
        requestingCancelFlight: action.setting
      }
    case types.SET_CANCEL_FLIGHT_SUCCESS:
      return {
        ...state,
        cancelFlightSuccess: action.setting
      }
    case types.SET_CANCEL_FLIGHT_ERROR:
      return {
        ...state,
        cancelFlightError: action.setting
      }
    case types.CLEAR_CANCEL_FLIGHT_DIALOG:
      return {
        ...state,
        cancelFlightError: false,
        cancelFlightSuccess: false,
        requestingCancelFlight: false
      }
    case types.REQUEST_PURGE_FLIGHT:
      return {
        ...state,
        requestPurgeFlight: action.setting
      }
    case types.SET_SHOW_END_FLIGHT_DIALOG:
      return {
        ...state,
        showEndFlightDialog: action.setting
      }
    case types.SET_TRAFFIC_CLIENT:
      return {
        ...state,
        trafficClient: action.client
      }
    case types.RECEIVE_SA_MESSAGES:
      return {
        ...state,
        SAmessages: action.messages
      }
    case types.RECEIVE_ALERT_MESSAGES:
      return {
        ...state,
        alertMessages: action.messages
      }
    case types.SET_SELECTED_TRAFFIC:
      return {
        ...state,
        selectedTraffic: action.id
      }
    case types.CLEAR_TRAFFIC:
      return {
        ...state,
        alertMessages: [],
        SAmessages: []
      }
    default:
      return state
  }
}
