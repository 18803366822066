import { keyBy } from 'modules/core/helpers/utilities.helpers'
import * as types from './action-types'

export const initialState = {
  aircraft: {},
  addAircraftMode: false,
  manufacturers: [],
  models: [],
  selectedAircraft: {},
  newAircraftName: '',
  manufacturerValue: '',
  modelValue: '',
  updateAircraftListSuccess: false,
  showRemoveAircraftDialog: false,
  removeAircraftSuccess: false,
  newAircraftId: ''
}

export function aircraftReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ADD_AIRCRAFT_MODE:
      return {
        ...state,
        addAircraftMode: action.setting
      }
    case types.RECEIVE_AIRCRAFT_DATA:
      return {
        ...state,
        aircraft: {
          ...state.aircraft,
          ...keyBy(action.data, 'id')
        }
      }
    case types.REMOVE_AIRCRAFT_DATA:
      return {
        ...state,
        aircraft: {
          ...keyBy(action.data, 'id')
        }
      }
    case types.RECEIVE_AIRCRAFT_MANUFACTURERS:
      return {
        ...state,
        manufacturers: action.data
      }
    case types.RECEIVE_AIRCRAFT_MODELS:
      return {
        ...state,
        models: action.data
      }
    case types.UPDATE_AIRCRAFT_NAME:
      return {
        ...state,
        selectedAircraft: { ...state.selectedAircraft, nickname: action.value }
      }
    case types.SET_SELECTED_AIRCRAFT:
      return {
        ...state,
        selectedAircraft: action.aircraft
      }
    case types.SET_NEW_AIRCRAFT_NAME:
      return {
        ...state,
        newAircraftName: action.value
      }
    case types.SET_MANUFACTURER_VALUE:
      return {
        ...state,
        manufacturerValue: action.value
      }
    case types.SET_MODEL_VALUE:
      return {
        ...state,
        modelValue: action.value
      }
    case types.CLEAR_AIRCRAFT_MODAL_VALUES:
      return {
        ...state,
        modelValue: '',
        manufacturerValue: '',
        newAircraftName: ''
      }
    case types.SET_UPDATE_AIRCRAFT_LIST_SUCCESS:
      return {
        ...state,
        updateAircraftListSuccess: action.setting
      }
    case types.SET_SHOW_REMOVE_AIRCRAFT_DIALOG:
      return {
        ...state,
        showRemoveAircraftDialog: action.setting
      }
    case types.SET_REMOVE_AIRCRAFT_SUCCESS:
      return {
        ...state,
        removeAircraftSuccess: action.setting
      }
    case types.SET_ADD_AIRCRAFT_SUCCESS:
      return {
        ...state,
        newAircraftId: action.id
      }
    default:
      return state
  }
}
