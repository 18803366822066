import { appVersion } from "../../../app-version";
import { AirmapLogo } from '../shared/airmap.logo'
import { AirmapPrivacyPolicy } from '../shared/airmap.privacy-policy'
import { AirmapWarrantyOfService } from '../shared/airmap.service-warranty'
import { ReactNode } from "react";
import { TextLink } from "../shared/config.types";
import { PaletteOptions } from "@material-ui/core/styles";

export type AirmapWebAppWhiteLabelConfig = {
  Settings: {
    SocialMedia: TextLink,
    AppVersion: TextLink,
    Faq: TextLink,
    PrivacyPolicy: TextLink,
    TermsAndConditions: TextLink
  },
  PolicyContent: () => ReactNode,
  Logo: () => ReactNode,
  Palette: PaletteOptions,
  ProjectTraffic: true,
  WarrantyOfService: string | null,
  MapSettings: {
    defaultLocation: {
      latitude: number,
      longitude: number
    }
  },
  MobileApps: {
    GooglePlayLink: string,
    AppleAppStoreLink: string
  },
  showShortMessageAfterSubmission: boolean
}

export const airmapWebAppWhiteLabelConfig: AirmapWebAppWhiteLabelConfig = {
  Settings: {
    SocialMedia: {
      Text: 'Follow @AirMapIO',
      Link: 'https://twitter.com/AirMapIO'
    },
    AppVersion: appVersion,
    Faq: {
      Text: 'FAQ',
      Link: 'https://support.airmap.com/hc/en-us'
    },
    PrivacyPolicy: {
      Text: 'Privacy Policy',
      Link: 'https://www.airmap.com/privacy-policy/'
    },
    TermsAndConditions: {
      Text: 'Terms and Conditions',
      Link: 'https://www.airmap.com/terms-service/'
    }
  },
  PolicyContent: AirmapPrivacyPolicy,
  Logo: AirmapLogo,
  Palette: {
    primary: {
      main: '#0057a2',
      light: '#5083d4',
      dark: '#002f73',
      lightText: '#959a9e',
      contrastText: '#fff'
    },
    secondary: {
      main: '#353f48',
      light: '#556571',
      dark: '#232a31',
      contrastText: '#363e47'
    },
    surface: '#fff',
    tables: {
      main: '#abafb2'
    }
  },
  ProjectTraffic: true,
  WarrantyOfService: AirmapWarrantyOfService,
  MapSettings: {
    defaultLocation: {
      latitude: 34.017931,
      longitude: -118.496046
    }
  },
  MobileApps: {
    GooglePlayLink:
      'https://play.google.com/store/apps/details?id=com.airmap.airmap&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
    AppleAppStoreLink:
      'https://apps.apple.com/us/app/airmap-for-drones/id1042824733?itsct=apps_box_badge&amp;itscg=30200'
  },

  //FIXME: We should use only one message and overwrite it with translation folders for each clients
  showShortMessageAfterSubmission: true
}
