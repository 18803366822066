import './App.styles.css'
import React from 'react'
import ReactDOM from 'react-dom'

import settings from 'settings'
import configureStore from './App.store'
import { AppComponent } from './App.component'

import { ApplicationInsights } from '@microsoft/applicationinsights-web';


// Polyfills

// Polyfill global for mqtt 
if (typeof window.global === 'undefined') {
  window.global = window;
}
import { Buffer as BufferPolyfill } from 'buffer'
globalThis.Buffer = BufferPolyfill

import process from "process"
window.process = process


// Analytics setup
const {
  firebaseAnalytics: { pageview },
  app_insights_connection,
  app_maintenance_mode
} = settings

const initialState = {}
export const { store, history } = configureStore(initialState)

// Firebase
pageview()
history.listen(() => pageview())

// Azure app insights
if (app_insights_connection) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: app_insights_connection
    }
  });
  appInsights.loadAppInsights();
} else {
  console.warn("App Insights disabled, please provide the connection string.")
}

// Redirect to the maintenance page if the maintenance mode is activated. 
if (app_maintenance_mode.active) {
  window.location.href = app_maintenance_mode.url
}

ReactDOM.render(
  <AppComponent history={history} store={store} />,
  document.getElementById('content')
)
