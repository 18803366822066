import * as types from './action-types'
import { MeasurementTypes } from 'settings/constants'
import settings from 'settings'

const {
  webAppWhiteLabelConfig: { MapSettings }
} = settings

export const initialState = {
  isError: false,
  display: false,
  messageColor: '',
  isSuccess: false,
  statusMsg: '',
  appDataLoaded: false,
  preferences: {
    map_center_coordinate: { ...MapSettings.defaultLocation },
    map_zoom_level: 9,
    map_style: 'light',
    ruleset_ids: [],
    disabled_ruleset_ids: []
  },
  editMode: false,
  selectedItem: '',
  errorType: '',
  errorMsg: '',
  locale: '',
  measurementType: MeasurementTypes.IMPERIAL,
  isFlightPublic: true,
  publicFlights: [],
  publicFlightsReceived: false
}

export function homeReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_ALERT:
      return {
        ...state,
        isError: false,
        isSuccess: false,
        display: false,
        messageColor: '',
        statusMsg: ''
      }
    case types.ERROR_GENERIC:
      return {
        ...state,
        isError: true,
        display: true,
        messageColor: 'red',
        statusMsg: action.statusMsg
      }
    case types.SUCCESS_ACTION:
      return {
        ...state,
        display: true,
        messageColor: 'green',
        isSuccess: true,
        statusMsg: action.statusMsg
      }
    case types.APP_DATA_LOADED:
      return {
        ...state,
        appDataLoaded: true
      }
    case types.SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.setting
      }
    case types.RECEIVE_USER_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.data
        }
      }
    case types.SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.selectedItem
      }
    case types.CLEAR_ERRORS:
      return {
        ...state,
        errorType: '',
        errorMsg: ''
      }
    case types.SET_LOCALE:
      return {
        ...state,
        locale: action.locale
      }
    case types.SET_MEASUREMENT_TYPE:
      return {
        ...state,
        measurementType: action.measurementType
      }
    case types.SET_FLIGHT_ACCESSIBILITY:
      return {
        ...state,
        isFlightPublic: action.isFlightPublic
      }
    case types.RECEIVE_PUBLIC_FLIGHTS:
      return {
        ...state,
        publicFlights: action.flights,
        publicFlightsReceived: true
      }
    case types.SET_PUBLIC_FLIGHTS_RECEIVED:
      return {
        ...state,
        publicFlightsReceived: action.setting
      }
    case types.CLEAR_HOME_REDUCER_VALUES: {
      return {
        ...state,
        isError: false,
        display: false,
        messageColor: '',
        isSuccess: false,
        statusMsg: '',
        editMode: false,
        selectedItem: '',
        errorType: '',
        errorMsg: ''
      }
    }
    default:
      return state
  }
}
