import moment from 'moment'
import * as types from './action-types'
import { RECEIVE_FLIGHT_PLAN, CLEAR_FLIGHT_PLANNING_VALUES } from '../FlightPlanning/action-types'

export const initialState = {
  altitude: 30.48,
  flightStartDate: moment().format(),
  flightEndDate: moment()
    .add(15, 'minutes')
    .format(),
  //array of flight feature questions to display on form
  flightFeatures: [],
  // key/value object pairs of saved answers to flight feature questions
  savedFlightFeatures: {},
  enableBriefButton: false,
  requestFlightContext: false
}

export function detailFormReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ALTITUDE:
      return {
        ...state,
        altitude: action.altitude
      }
    case types.SET_FLIGHT_START_END_DATE:
      return {
        ...state,
        flightStartDate: action.startDate,
        flightEndDate: action.endDate
      }
    case RECEIVE_FLIGHT_PLAN:
      return {
        ...state,
        altitude: action.flightPlan.max_altitude_agl,
        savedFlightFeatures: action.flightPlan.flight_features || {},
        flightStartDate: action.flightPlan.start_time,
        flightEndDate: action.flightPlan.end_time
      }
    case types.SET_FLIGHT_FEATURES:
      return {
        ...state,
        flightFeatures: action.features
      }
    case types.SET_SAVED_FLIGHT_FEATURE:
      return {
        ...state,
        savedFlightFeatures: getUpdatedSavedFlightFeatures(
          state.savedFlightFeatures,
          action.flightFeatureId,
          action.value
        )
      }
    case types.CLEAR_SAVED_FLIGHT_FEATURES:
      return {
        ...state,
        savedFlightFeatures: {}
      }
    case types.SET_ENABLE_BRIEF_BUTTON:
      return {
        ...state,
        enableBriefButton: action.setting
      }
    case types.SET_REQUEST_FLIGHT_CONTEXT:
      return {
        ...state,
        requestFlightContext: action.setting
      }
    case CLEAR_FLIGHT_PLANNING_VALUES:
      return {
        ...initialState
      }
    default:
      return state
  }
}

const getUpdatedSavedFlightFeatures = (savedFlightFeatures, newKey, newValue) => {
  const newObj = { ...savedFlightFeatures }
  newObj[newKey] = newValue
  return newObj
}
