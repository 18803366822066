import { createRulesPluginContainer } from 'modules/rules-plugin/RulesPlugin.container'

const reducerId = 'rulesPluginDrawing'

const { rulesPluginStore, rulesPluginSelectors, RulesPluginContainer } = createRulesPluginContainer([reducerId])

export {
  rulesPluginStore as rulesPluginStoreDrawing,
  rulesPluginSelectors as rulesPluginSelectorsDrawing,
  reducerId as rulesDrawingReducerId,
  RulesPluginContainer as RulesPluginContainerDrawing
}
