import * as types from './terms-and-conditions-agreement-modal.types'

export const initialState = {
  hasAgreedTermsAndConditions: false,
  agreementTermsAndConditions: [],
  agreementId: ''
}

export function termsAndConditionsAgreementModalReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_HAS_AGREED_TERMS_AND_CONDITIONS:
      return {
        ...state,
        hasAgreedTermsAndConditions: action.status
      }
    case types.SET_AGREEMENT_ID:
      return {
        ...state,
        agreementId: action.agreementId || initialState.agreementId
      }
    case types.SET_AGREEMENT_TERMS_AND_CONDITIONS:
      return {
        ...state,
        agreementTermsAndConditions: action.agreementTermsAndConditions
      }
    default:
      return state
  }
}
