import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router'

import { Loader } from 'modules/shared-components/Loader/Loader.component'
import EnvironmentIndicatorBanner from 'projects/web-app/components/EnvironmentIndicators/EnvironmentIndicatorBanner'
import NotFound from 'projects/web-app/components/NotFound'

import settings from 'settings'
import { NODE_ENV, WHITE_LABEL_ENV } from '@env'


const WebAppComponent = lazy(() => import('./web-app/App.web-app.container'))
const SFOAppContainer = lazy(() => import('./SFO/App.sfo.container'))


const { show_sfo, show_webapp } = settings
const isDevelopment = NODE_ENV === 'development'
const isSkyguideCompany = WHITE_LABEL_ENV === 'skyguide'
const shouldSFOBeRoot = isSkyguideCompany && show_sfo && !show_webapp

export const Routes = () => {
  return (
    <div>
      {isDevelopment && <EnvironmentIndicatorBanner />}

      <Suspense fallback={<Loader />}>
        <Switch>
          {show_webapp && <Route exact path={['/', '/geo', '/plan']} component={WebAppComponent} />}

          {shouldSFOBeRoot && (
            <Route exact path="/">
              <Redirect to="/operator/requests" />
            </Route>
          )}

          {show_sfo && <Route exact path={'**/(requests|activities)**'} component={SFOAppContainer} />}

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </div>
  )
}
