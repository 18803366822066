import * as types from './action-types'

export const initialState = {
  flightWeather: [],
  requestingWeather: false,
  flightLocation: '',
  flightBrief: {},
  requestingBrief: false,
  flightBriefError: false,
  openViolatingRulesAlert: false,
  showLAANCModal: false,
  hasAgreedLAANCRules: false,
  hasAgreedViolatingRules: false,
  agreementId: ''
}

export function briefModalReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_FLIGHT_WEATHER:
      return {
        ...state,
        flightWeather: action.flightWeather
      }
    case types.WEATHER_REQUEST_IN_PROGRESS:
      return {
        ...state,
        requestingWeather: action.setting
      }
    case types.SET_FLIGHT_LOCATION:
      return {
        ...state,
        flightLocation: action.location
      }
    case types.SET_FLIGHT_BRIEF:
      return {
        ...state,
        flightBrief: { ...state.flightBrief, ...action.flightBrief }
      }
    case types.SET_REQUESTING_BRIEF:
      return {
        ...state,
        requestingBrief: action.setting
      }
    case types.SET_FLIGHT_BRIEF_ERROR: {
      return {
        ...state,
        flightBriefError: action.setting
      }
    }
    case types.SET_OPEN_VIOLATING_RULES_ALERT: {
      return {
        ...state,
        openViolatingRulesAlert: action.setting
      }
    }
    case types.SET_SHOW_LAANC_MODAL:
      return {
        ...state,
        showLAANCModal: action.status
      }
    case types.SET_HAS_AGREED_LAANC_RULES:
      return {
        ...state,
        hasAgreedLAANCRules: action.status
      }
    case types.SET_HAS_AGREED_VIOLATING_RULES:
      return {
        ...state,
        hasAgreedViolatingRules: action.status
      }
    case types.SET_AGREEMENT_ID:
      return {
        ...state,
        agreementId: action.id
      }
    default:
      return state
  }
}
