import { CoreMessages } from 'modules/core/core.messages'

export const WebAppCoreMessages = {
  ...CoreMessages,
  advisoryLabel_airport: {
    id: 'Core.advisoryLabel_airport',
    defaultMessage: 'Airport'
  },
  advisoryLabel_c0_areas: {
    id: 'Core.advisoryLabel_c0_areas',
    defaultMessage: 'Zones prohibited to all drones'
  },
  advisoryLabel_tma: {
    id: 'Core.advisoryLabel_tma',
    defaultMessage: 'TMA'
  },
  advisoryLabel_ama_field: {
    id: 'Core.advisoryLabel_ama_field',
    defaultMessage: 'AMA Field'
  },
  advisoryLabel_city: {
    id: 'Core.advisoryLabel_city',
    defaultMessage: 'City'
  },
  advisoryLabel_controlled_airspace: {
    id: 'Core.advisoryLabel_controlled_airspace',
    defaultMessage: 'Controlled Airspace'
  },
  advisoryLabel_country: {
    id: 'Core.advisoryLabel_country',
    defaultMessage: 'Country'
  },
  advisoryLabel_county: {
    id: 'Core.advisoryLabel_county',
    defaultMessage: 'County'
  },
  advisoryLabel_custom: {
    id: 'Core.advisoryLabel_custom',
    defaultMessage: 'Custom'
  },
  advisoryLabel_embassy: {
    id: 'Core.advisoryLabel_embassy',
    defaultMessage: 'Embassy'
  },
  advisoryLabel_emergency: {
    id: 'Core.advisoryLabel_emergency',
    defaultMessage: 'Emergency'
  },
  advisoryLabel_federal_building: {
    id: 'Core.advisoryLabel_federal_building',
    defaultMessage: 'Federal Building'
  },
  advisoryLabel_fir: {
    id: 'Core.advisoryLabel_fir',
    defaultMessage: 'FIR'
  },
  advisoryLabel_fire: {
    id: 'Core.advisoryLabel_fire',
    defaultMessage: 'Fire'
  },
  advisoryLabel_gliderport: {
    id: 'Core.advisoryLabel_gliderport',
    defaultMessage: 'Gliderport'
  },
  advisoryLabel_hazard_area: {
    id: 'Core.advisoryLabel_hazard_area',
    defaultMessage: 'Hazard Area'
  },
  advisoryLabel_heliport: {
    id: 'Core.advisoryLabel_heliport',
    defaultMessage: 'Heliport'
  },
  advisoryLabel_highway: {
    id: 'Core.advisoryLabel_highway',
    defaultMessage: 'Highway'
  },
  advisoryLabel_hospital: {
    id: 'Core.advisoryLabel_hospital',
    defaultMessage: 'Hospital'
  },
  advisoryLabel_industrial_property: {
    id: 'Core.advisoryLabel_industrial_property',
    defaultMessage: 'Industrial Property'
  },
  advisoryLabel_jpn_base: {
    id: 'Core.advisoryLabel_jpn_base',
    defaultMessage: 'Japan Base'
  },
  advisoryLabel_military_property: {
    id: 'Core.advisoryLabel_military_property',
    defaultMessage: 'Military Property'
  },
  advisoryLabel_notam: {
    id: 'Core.advisoryLabel_notam',
    defaultMessage: 'NOTAM'
  },
  advisoryLabel_park: {
    id: 'Core.advisoryLabel_park',
    defaultMessage: 'Park'
  },
  advisoryLabel_police_station: {
    id: 'Core.advisoryLabel_police_station',
    defaultMessage: 'Police Station'
  },
  advisoryLabel_power_plant: {
    id: 'Core.advisoryLabel_power_plant',
    defaultMessage: 'Power Plant'
  },
  advisoryLabel_powerline: {
    id: 'Core.advisoryLabel_powerline',
    defaultMessage: 'Powerline'
  },
  advisoryLabel_prison: {
    id: 'Core.advisoryLabel_prison',
    defaultMessage: 'Prison'
  },
  advisoryLabel_railway: {
    id: 'Core.advisoryLabel_railway',
    defaultMessage: 'Railway'
  },
  advisoryLabel_recreational_area: {
    id: 'Core.advisoryLabel_recreational_area',
    defaultMessage: 'Recreational Area'
  },
  advisoryLabel_residential_property: {
    id: 'Core.advisoryLabel_residential_property',
    defaultMessage: 'Residential Property'
  },
  advisoryLabel_school: {
    id: 'Core.advisoryLabel_school',
    defaultMessage: 'School'
  },
  advisoryLabel_seaplane_base: {
    id: 'Core.advisoryLabel_seaplane_base',
    defaultMessage: 'Seaplane Base'
  },
  advisoryLabel_special_use_airspace: {
    id: 'Core.advisoryLabel_special_use_airspace',
    defaultMessage: 'Special Use Airspace'
  },
  advisoryLabel_stadium: {
    id: 'Core.advisoryLabel_stadium',
    defaultMessage: 'Stadium'
  },
  advisoryLabel_state: {
    id: 'Core.advisoryLabel_state',
    defaultMessage: 'State'
  },
  advisoryLabel_subprefecture: {
    id: 'Core.advisoryLabel_subprefecture',
    defaultMessage: 'Subprefecture'
  },
  advisoryLabel_supercity: {
    id: 'Core.advisoryLabel_supercity',
    defaultMessage: 'Supercity'
  },
  advisoryLabel_tfr: {
    id: 'Core.advisoryLabel_tfr',
    defaultMessage: 'TFR'
  },
  advisoryLabel_ulm_field: {
    id: 'Core.advisoryLabel_ulm_field',
    defaultMessage: 'Ulm Field'
  },
  advisoryLabel_university: {
    id: 'Core.advisoryLabel_university',
    defaultMessage: 'University'
  },
  advisoryLabel_waterway: {
    id: 'Core.advisoryLabel_waterway',
    defaultMessage: 'Waterway'
  },
  advisoryLabel_wildfire: {
    id: 'Core.advisoryLabel_wildfire',
    defaultMessage: 'Wildfire'
  },
  advisoryLabel_landing_sites: {
    id: 'Core.advisoryLabel_landing_sites',
    defaultMessage: 'Landing Site'
  }
}
