import { combineReducers } from '@reduxjs/toolkit'

import { termsAndConditionsAgreementModalReducer } from 'projects/web-app/components/TermsAndConditionsAgreementModal/store/terms-and-conditions-agreement-modal.reducer'
import {
  rulesHomeReducerId,
  rulesPluginStoreHome
} from 'projects/web-app/components/RulesPluginViews/RulesPluginHome.container'
import {
  rulesDrawingReducerId,
  rulesPluginStoreDrawing
} from 'projects/web-app/components/RulesPluginViews/RulesPluginDrawing.container'
import {
  rulesPreviewReducerId,
  rulesPluginStorePreview
} from 'projects/web-app/components/RulesPluginViews/RulesPluginPreview.container'

import { webAppReducers } from 'projects/web-app/App.web-app.reducers'
import { templateReducers } from 'projects/SFO/App.sfo.reducers'

export default (routerReducer) => combineReducers({
  ...webAppReducers(routerReducer),
  template: templateReducers,
  termsAndConditionsAgreementModal: termsAndConditionsAgreementModalReducer,
  [rulesHomeReducerId]: rulesPluginStoreHome,
  [rulesDrawingReducerId]: rulesPluginStoreDrawing,
  [rulesPreviewReducerId]: rulesPluginStorePreview
})
