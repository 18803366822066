export const SUCCESS_ACTION = 'SUCCESS_ACTION'
export const ERROR_GENERIC = 'ERROR_GENERIC'
export const CLEAR_ALERT = 'CLEAR_ALERT'
export const RECEIVE_USER_PREFERENCES = 'RECEIVE_USER_PREFERENCES'
export const APP_DATA_LOADED = 'APP_DATA_LOADED'
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_MEASUREMENT_TYPE = 'SET_MEASUREMENT_TYPE'
export const SET_FLIGHT_ACCESSIBILITY = 'SET_FLIGHT_ACCESSIBILITY'
export const RECEIVE_PUBLIC_FLIGHTS = 'RECEIVE_PUBLIC_FLIGHTS'
export const SET_PUBLIC_FLIGHTS_RECEIVED = 'SET_PUBLIC_FLIGHTS_RECEIVED'
export const CLEAR_HOME_REDUCER_VALUES = 'CLEAR_HOME_REDUCER_VALUES'
