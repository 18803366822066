import deWarranty from './de'
import enWarranty from './en'
import frWarranty from './fr'
import itWarranty from './it'

import getLanguageLocaleFromNavigator from '../../../../helpers/getLanguageLocaleFromNavigator'

const warrantiesByLang = {
  de: deWarranty,
  fr: frWarranty,
  en: enWarranty,
  it: itWarranty
}

const language = getLanguageLocaleFromNavigator()

const warranty = warrantiesByLang[language] ? warrantiesByLang[language] : warrantiesByLang['en']

export const skyguideServiceWarranty = warranty
