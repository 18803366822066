import { airmapConfigMap } from './airmap/airmap.config'
import { skyguideConfigMap } from './skyguide/skyguide.config'
import { rakutenConfigMap } from './rakuten/rakuten.config'

import { WHITE_LABEL_ENV } from '@env'

const whiteLabelEnv = WHITE_LABEL_ENV || 'airmap'

const whiteLabelConfig = {
  airmap: airmapConfigMap,
  skyguide: skyguideConfigMap,
  rakuten: rakutenConfigMap,
}

const airmapWhiteLabelConfig = whiteLabelConfig['airmap']

export const {
  webApp: webAppWhiteLabelConfig = airmapWhiteLabelConfig.webApp,
  sfo: sfoWhiteLabelConfig = airmapWhiteLabelConfig.sfo
} = whiteLabelConfig[whiteLabelEnv]

