import * as types from './map.types'
import { bufferInputFormattingByMeasurement } from '../constants/map.constants'
import { drawModes } from '../../draw-plugin/constants/draw-plugin.constants'

const initialState = {
  mapInstance: {},
  mapLoaded: false,
  rulesetsChanged: false,
  allTemplateRulesets: [],
  mapLayers: {
    classifiedLayers: [],
    unclassifiedLayers: [],
  },
  advisories: {},
  advisoryCount: 0,
  advisoriesLastUpdated: '',
  // when a single ruleset is being viewed these are its rules
  rulesetView: {
    isViewingRuleset: false,
    ruleset: {},
  },
  drawMode: drawModes.DRAW_POINT,
  buffer: bufferInputFormattingByMeasurement.imperial.defaultInputValue,
  templateColor: 'grey',
  drawingId: '',
  showConfirmDeleteDrawingDialog: false,
  confirmDeleteEntireDrawing: false,
  geometryIntersectionError: false,
  showUploadGeojsonModal: false,
  uploadingGeojson: false,
  uploadGeojsonFeature: {},
  scaleControl: {},
  rawFeatureGeometry: {},
  layersMenuStatus: false,
  isOperationGeometryPopUpOpen: false,
  isOperationGeometryListPopUpOpen: false,
  operationGeometryPopUpInfo: {},
  operationGeometryListPopUpInfo: [],
  operationGeometryCoordinates: [],
  operationGeometryListPopUpCoordinates: [],
  experimentalAircraftTypeFilter: '',
  isExperimentalFiltersEnabled: true,
  rulesetsIds: '',
  showClickedAdvisoriesBox: false,
  isEditionModeEnabled: true,
  mapBounds: {},
  highlightedGeometryId: '',
  highlightedConflictId: undefined,
}

export function mapReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_TEMPLATE_MAP_INSTANCE:
      return {
        ...state,
        mapInstance: action.mapInstance,
        mapLoaded: action.mapLoaded,
      }
    case types.SET_TEMPLATE_RULESETS:
      return {
        ...state,
        allTemplateRulesets: action.allTemplateRulesets,
      }
    case types.RECEIVE_TEMPLATE_MAP_STYLES:
      return {
        ...state,
        mapLayers: { classifiedLayers: action.classifiedLayers, unclassifiedLayers: action.unclassifiedLayers },
      }
    case types.RECEIVE_TEMPLATE_ADVISORIES:
      return {
        ...state,
        advisories: action.advisories,
        advisoryCount: action.advisoryCount,
        advisoriesLastUpdated: action.advisoriesLastUpdated,
      }
    case types.VIEW_RULESET_INFO:
      return {
        ...state,
        rulesetView: {
          isViewingRuleset: true,
          ruleset: action.ruleset,
        },
      }
    case types.HIDE_RULESET_INFO:
      return {
        ...state,
        rulesetView: {
          isViewingRuleset: false,
          ruleset: {},
        },
      }
    case types.UNMOUNT_MAP_PURGE_STORE:
      return initialState
    case types.MANUALLY_SET_TEMPLATE_DRAW_MODE:
      return {
        ...state,
        drawMode: action.mode,
      }
    case types.SET_TEMPLATE_COLOR:
      return {
        ...state,
        templateColor: action.color,
      }
    case types.SET_TEMPLATE_DRAWING_ID:
      return {
        ...state,
        drawingId: action.id,
      }

    case types.SET_SHOW_CONFIRM_DELETE_DRAWING_DIALOG:
      return {
        ...state,
        showConfirmDeleteDrawingDialog: action.setting,
      }
    case types.SET_TEMPLATE_BUFFER:
      return {
        ...state,
        buffer: action.buffer,
      }
    case types.SET_CONFIRM_DELETE_ENTIRE_TEMPLATE_DRAWING:
      return {
        ...state,
        confirmDeleteEntireDrawing: action.setting,
        showConfirmDeleteDrawingDialog: false,
      }
    case types.SET_TEMPLATE_GEOMETRY_INTERSECTION_ERROR:
      return {
        ...state,
        geometryIntersectionError: action.setting,
      }
    case types.SET_SHOW_UPLOAD_TEMPLATE_GEOJSON_MODAL:
      return {
        ...state,
        showUploadGeojsonModal: action.setting,
      }
    case types.SET_UPLOADING_TEMPLATE_GEOJSON:
      return {
        ...state,
        uploadingGeojson: action.setting,
      }
    case types.SET_UPLOAD_GEOJSON_FEATURES:
      return {
        ...state,
        uploadGeojsonFeature: action.feature,
      }
    case types.SET_SCALE_CONTROL:
      return {
        ...state,
        scaleControl: action.control,
      }
    case types.SET_RAW_FEATURE_GEOMETRY:
      return {
        ...state,
        templateColor: 'grey',
        rawFeatureGeometry: action.feature,
      }
    case types.REBUILD_MAP:
      return {
        ...state,
        mapInstance: {},
        mapLoaded: false,
        mapLayers: {
          classifiedLayers: [],
          unclassifiedLayers: [],
        },
      }
    case types.TOGGLE_LAYERS_MENU_STATUS:
      return {
        ...state,
        layersMenuStatus: !state.layersMenuStatus,
      }
    case types.OPEN_OPERATION_GEOMETRY_POP_UP:
      return {
        ...state,
        isOperationGeometryPopUpOpen: true,
      }
    case types.CLOSE_OPERATION_GEOMETRY_POP_UP:
      return {
        ...state,
        isOperationGeometryPopUpOpen: false,
        operationGeometryPopUpInfo: {},
        operationGeometryCoordinates: [],
      }
    case types.OPEN_OPERATION_GEOMETRY_LIST_POP_UP:
      return {
        ...state,
        isOperationGeometryListPopUpOpen: true,
      }
    case types.CLOSE_OPERATION_GEOMETRY_LIST_POP_UP:
      return {
        ...state,
        isOperationGeometryListPopUpOpen: false,
        operationGeometryListPopUpInfo: [],
        operationGeometryListPopUpCoordinates: [],
      }
    case types.SET_OPERATION_GEOMETRY_LIST_POP_UP_INFO:
      return {
        ...state,
        operationGeometryListPopUpInfo: action.geometriesList,
      }
    case types.SET_OPERATION_GEOMETRY_LIST_POP_UP_COORDINATES:
      return {
        ...state,
        operationGeometryListPopUpCoordinates: action.coords,
      }
    case types.SET_OPERATION_GEOMETRY_POP_UP_INFO:
      return {
        ...state,
        operationGeometryPopUpInfo: action.features,
      }
    case types.SET_OPERATION_GEOMETRY_COORDINATES:
      return {
        ...state,
        operationGeometryCoordinates: action.coords,
      }
    case types.SET_EXPERIMENTAL_AIRCRAFT_TYPE_FILTER:
      return {
        ...state,
        experimentalAircraftTypeFilter: action.experimentalAircraftTypeFilter,
      }
    case types.DISABLE_EXPERIMENTAL_FILTERS:
      return {
        ...state,
        isExperimentalFiltersEnabled: false,
      }
    case types.ENABLE_EXPERIMENTAL_FILTERS:
      return {
        ...state,
        isExperimentalFiltersEnabled: true,
      }
    case types.SET_RULESETS_IDS:
      return {
        ...state,
        rulesetsIds: action.rulesetsIds,
      }
    case types.SHOW_CLICKED_ADVISORIES_BOX:
      return {
        ...state,
        showClickedAdvisoriesBox: action.setting,
      }
    case types.IS_EDITION_MODE_ENABLED:
      return {
        ...state,
        isEditionModeEnabled: action.setting,
      }
    case types.SET_MAP_BOUNDS:
      return {
        ...state,
        mapBounds: action.mapBounds,
      }
    case types.SET_HIGHLIGHTED_GEOMETRY_ID:
      return {
        ...state,
        highlightedGeometryId: action.geometryId,
      }
    case types.SET_HIGHLIGHTED_CONFLICT_ID:
      return {
        ...state,
        highlightedConflictId: action.conflictId,
      }
    default:
      return state
  }
}
