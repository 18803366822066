import { appVersion } from '../../../app-version'
import { SkyguideLogo } from '../shared/skyguide.logo'
import { skyguidePrivacyPolicy } from '../shared/privacy-policy/skyguide.privacy-policy'
import { skyguideServiceWarranty } from '../shared/service-warranty/skyguide.service-warranty'

import { privacyPolicyLink, termsAndConditionsLink } from '../shared/privacy-policy/common'
import { Location, TextLink, TextLinkWording } from "../../airmap/shared/config.types";
import { ReactNode } from "react";
import { PaletteOptions } from "@material-ui/core/styles";

export type SkyguideWebAppWhiteLabelConfig = {
  Settings: {
    SocialMedia: TextLink,
    AppVersion: TextLink,
    Faq: TextLink,
    About: TextLinkWording,
    PrivacyPolicy: TextLink,
    TermsAndConditions: TextLink,
    Support: TextLink,
  },
  PolicyContent: () => ReactNode,
  Logo: () => ReactNode,
  Palette: PaletteOptions,
  ProjectTraffic: boolean,
  WarrantyOfService: string,
  MapSettings: {
    defaultLocation: Location,
    availableJurisdictionUUIDs: string[],
    geocoderBoundingBox: [number, number, number, number],
  },
  MobileApps: {
    AppleAppStoreLink: string,
  },
  hideAuthorizationStatusFromBriefPlanModal: boolean,
}

export const skyguideWebAppWhiteLabelConfig: SkyguideWebAppWhiteLabelConfig = {
  Settings: {
    SocialMedia: {
      Text: 'Follow @skyguide',
      Link: 'https://twitter.com/skyguide',
    },
    AppVersion: appVersion,
    Faq: {
      Text: 'FAQ',
      Link: 'https://www.skyguide.ch/swiss-u-space/faq',
    },
    About: {
      wording: 'about',
      Text: 'About',
      Link: 'https://www.skyguide.ch/services/drone-flights',
    },
    PrivacyPolicy: {
      Text: 'Privacy Policy',
      Link: privacyPolicyLink,
    },
    TermsAndConditions: {
      Text: 'Terms and Conditions',
      Link: termsAndConditionsLink,
    },
    Support: {
      Text: 'Support',
      Link: 'https://www.skyguide.ch/swiss-u-space/support',
    },
  },
  PolicyContent: skyguidePrivacyPolicy,
  Logo: SkyguideLogo,
  Palette: {
    primary: {
      main: '#0057a2',
      light: '#A3B1DE',
      dark: '#002f73',
      lightText: '#959a9e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#05477F',
      light: '#05477F',
      dark: '#05477F',
      contrastText: '#fff',
    },
    surface: '#fff',
    tables: {
      main: '#abafb2',
    },
  },
  ProjectTraffic: false,
  WarrantyOfService: skyguideServiceWarranty,
  MapSettings: {
    defaultLocation: {
      latitude: 46.79856,
      longitude: 8.23197,
    },
    availableJurisdictionUUIDs: ['135e96b9-101f-4465-a78e-98247a1f3d58'],
    geocoderBoundingBox: [6.02260949059, 45.7769477403, 10.4427014502, 47.8308275417],
  },
  MobileApps: {
    AppleAppStoreLink: 'https://apps.apple.com/ar/app/swiss-u-space/id1471169224?itsct=apps_box_badge&amp;itscg=30200',
  },
  hideAuthorizationStatusFromBriefPlanModal: true,
}
