import {
  inputTypes,
  inputIDs,
  scopesIDs,
  optionalFieldsValidationFunction,
  inputValues,
  requirementTypes,
  inputTechnicalNames
} from '../constants/scopes.constants'
import { timestampFormatting } from 'modules/core/constants/date-time.constants'
import { airmapDate } from 'libs/airmap-date/airmap-date.service'
import moment from 'moment'
import { getScopeFieldsByTechnicalName } from 'projects/SFO/modules/core/store/core.helpers'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { SFOCoreMessages } from 'projects/SFO/modules/core/core.messages'
import { airmapIntl } from 'libs/airmap-intl'

export function getGeometryScopeAndField(scopes) {
  let geometryScope, geometryField

  for (let i = 0; i < scopes.length; i++) {
    const scope = scopes[i]
    const field = scope.fields.find(({ type }) => type === inputTypes.GEOMETRY)

    if (field) {
      geometryScope = scope
      geometryField = field
      break
    }
  }

  return { geometryScope, geometryField }
}

export function parseScopesForAPI(scopes, isEvaluationProcess = true) {
  return scopes.reduce((scopes, scope) => {
    const cleanFields = removeEmptyFieldsForAPI(scope.fields)
    const parsedFields = parseFieldsForAPI(cleanFields, isEvaluationProcess)
    const areParsedFieldsEmpty = !parsedFields.length

    if (areParsedFieldsEmpty) {
      return scopes
    }

    return isEvaluationProcess
      ? [
        ...scopes,
        {
          scope_descriptor_id: scope.id,
          fields: parsedFields
        }
      ]
      : [
        ...scopes,
        {
          id: scope.id,
          scope_descriptor_id: scope.scope_descriptor_id,
          fields: parsedFields
        }
      ]
  }, [])
}

function parseFieldsForAPI(fields, isEvaluationProcess) {
  return fields.map(field => {
    let parsedField = {
      type: field.type,
      value: field.inputValue,
      ...getPostDataByFieldType(field, isEvaluationProcess)
    }
    return isEvaluationProcess
      ? {
        ...parsedField,
        field_descriptor_id: field.id
      }
      : {
        ...parsedField,
        id: field.id,
        field_descriptor_id: field.field_descriptor_id,
        isModification: field.isModification
      }
  })
}

function removeEmptyFieldsForAPI(fields) {
  return fields.reduce((fields, field) => {
    const isFieldEmpty = typeof field.inputValue === 'undefined' || field.inputValue === ''

    if (field.type === inputTypes.GROUP) {
      const cleanedGroupFields = removeEmptyFieldsForAPI(field.details.fields)
      const areCleanedGroupFieldsEmpty = !cleanedGroupFields.length

      return areCleanedGroupFieldsEmpty ? fields : [...fields, { ...field, details: { fields: cleanedGroupFields } }]
    } else {
      return isFieldEmpty ? fields : [...fields, field]
    }
  }, [])
}

function getPostDataByFieldType(field, isEvaluationProcess) {
  const { FLOATING_POINT, INTEGER, GEOMETRY, DATE, TIME, DATETIME, GROUP, DURATION, PHONE_NUMBER } = inputTypes

  if ([FLOATING_POINT, INTEGER, DURATION].includes(field.type)) {
    const fieldData = {
      value: Number(field.inputValue)
    }

    const hasUnitValue = Boolean(field.details.unit)

    if (hasUnitValue) {
      fieldData.unit = field.details.unit.si
    }

    return fieldData
  } else if (field.type === DATE) {
    return {
      value: moment(field.inputValue).format(timestampFormatting.DATE)
    }
  } else if (field.type === TIME) {
    return {
      value: moment(field.inputValue).format(timestampFormatting.TIME_0_23_HOURS_TIMEZONE)
    }
  } else if (field.type === DATETIME) {
    const parsedInputValue = airmapDate.formatInlineDate({ date: field.inputValue })

    return {
      value: moment(parsedInputValue).format()
    }
  } else if (field.type === GEOMETRY) {
    return {
      value: {
        footprint: field.inputValue.footprint ? field.inputValue.footprint : field.inputValue
      }
    }
  } else if (field.type === GROUP) {
    return {
      value: parseFieldsForAPI(field.details.fields, isEvaluationProcess)
    }
  } else if (field.type === PHONE_NUMBER) {
    return {
      value: field.inputValue
    }
  }
  return {}
}

export function parseOperationScopesForUI(scopeDescriptorArray, responseScopes) {
  return responseScopes.map(responseScope => {
    const scopeDescriptor = scopeDescriptorArray.find(({ id }) => id === responseScope.scope_descriptor_id)

    const formattedFields = parseFieldsToRender(responseScope.fields, scopeDescriptor)
    return {
      ...scopeDescriptor,
      ...responseScope,
      fields: formattedFields
    }
  })
}

export const parseScopePhoneNumber = scopes => {
  const scopeWithPhoneNumberFormated = scopes.map(scope => {
    if (scope.id == scopesIDs.APPLICANT) {
      const fieldsWithPhoneNumberFormated = scope.fields.map(field => {
        if (field.type === inputTypes.PHONE_NUMBER) {
          return {
            ...field,
            inputValue: parseFieldBackByType(field).inputValue
          }
        } else {
          return field
        }
      })
      return { ...scope, fields: fieldsWithPhoneNumberFormated }
    } else {
      return scope
    }
  })

  return scopeWithPhoneNumberFormated
}

function parseFieldsToRender(fields, scopeDescriptor) {
  return fields.map(apiField => {
    const descriptorField = scopeDescriptor.fields.find(
      field => apiField.field_descriptor_id === field.id || apiField.field_descriptor_id === field.field_descriptor_id
    )

    const updatedField = {
      ...descriptorField,
      ...apiField,
      inputValue: apiField.value.value || apiField.value
    }

    return {
      ...updatedField,
      ...parseFieldBackByType(updatedField, scopeDescriptor)
    }
  })
}

const getTimeZoneByName = (timeZoneName, currentTimeZoneName) => {
  const currentTimeZoneNameArray = currentTimeZoneName.split('/')
  const isCorrectTimeZone = currentTimeZoneNameArray.every(element => timeZoneName.indexOf(element) != -1)
  return isCorrectTimeZone
}

const getCountryByTimeZone = (country, currentTimeZoneName) => {
  const timeZonesInCountry = country[1].zones
  const selectedTimeZone = timeZonesInCountry.find(timeZoneName => getTimeZoneByName(timeZoneName, currentTimeZoneName))

  return Boolean(selectedTimeZone)
}

function parseFieldBackByType(field) {
  const { TIME, GROUP, FILE, PHONE_NUMBER, STRING_ENUMERATION } = inputTypes
  const { ALTITUDE_MINIMUM_REFERENCE, ALTITUDE_MAXIMUM_REFERENCE } = inputIDs
  const { FLIGHT_LEVEL } = inputValues

  if (field.type === TIME) {
    // We ignore the timezone when parsing data from the API, see SUSI-275 for more info
    const time = airmapDate.dateProvider(field.inputValue, timestampFormatting.TIME_0_23_HOURS)
    return {
      inputValue: moment.tz(time, airmapDate.getClientTimezone().name)
    }
  } else if (field.type === GROUP) {
    return {
      details: {
        fields: parseFieldsToRender(field.value, field.details)
      }
    }
  } else if (field.type === FILE) {
    return {
      inputValue: field.value.reference || field.value
    }
  } else if (field.type === PHONE_NUMBER) {
    const currentCountryCodeNumber = getCurrentCountryCode()
    const phoneNumberValid = isValidPhoneNumber(field.value)

    return {
      inputValue: phoneNumberValid ? field.value : currentCountryCodeNumber
    }
  } else if (field.type === STRING_ENUMERATION) {
    if (
      field.field_descriptor_id == ALTITUDE_MAXIMUM_REFERENCE ||
      field.field_descriptor_id == ALTITUDE_MINIMUM_REFERENCE
    ) {
      const detailsWithcustomFlightLevel = field.details?.values.map(detail => {
        if (detail.value == FLIGHT_LEVEL) {
          const customDisplayText = airmapIntl.translateMessage(SFOCoreMessages.flight_level)
          return { value: detail.value, display_text: customDisplayText }
        } else {
          return detail
        }
      })
      const detailsValue = detailsWithcustomFlightLevel
        ? {
          details: { values: detailsWithcustomFlightLevel }
        }
        : {}

      return detailsValue
    }
  }

  return {}
}

const getCurrentCountryCode = () => {
  const currentTimeZoneName = moment.tz.guess()
  const countries = Object.entries(moment.tz._countries)
  const currentCountry = countries.find(country => getCountryByTimeZone(country, currentTimeZoneName))

  const phoneUtil = PhoneNumberUtil.getInstance()
  const countryCode = phoneUtil
    .parseAndKeepRawInput('99', currentCountry[0])
    .getCountryCode()
    .toString()

  return countryCode
}
export const isValidPhoneNumber = number => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance()
    const phoneNumber = phoneUtil.parse('+' + number, '')
    const isValidPhoneNumber = phoneUtil.isValidNumber(phoneNumber)

    return isValidPhoneNumber
  } catch {
    return false
  }
}
const incompleteOrInvalidFieldsReducer = (
  incompleteOrInvalidFields,
  { inputValue, type, details: { fields }, requirement, name, errorMessage }
) => {
  if (type === inputTypes.GROUP) {
    return getIncompleteOrInvalidRequiredFields({ fields })
  } else {
    const isRequired = isFieldRequired(requirement)
    let isRequiredAndIncomplete

    if (type === inputTypes.PHONE_NUMBER) isRequiredAndIncomplete = isRequired && inputValue.length < 3
    else isRequiredAndIncomplete = isRequired && typeof inputValue !== 'boolean' && !inputValue && inputValue !== 0

    const doesFieldHaveErrorMessage = Boolean(errorMessage)
    return isRequiredAndIncomplete || doesFieldHaveErrorMessage
      ? [...incompleteOrInvalidFields, name]
      : incompleteOrInvalidFields
  }
}

export const getIncompleteOrInvalidRequiredFields = ({ fields }) => {
  return fields.reduce(incompleteOrInvalidFieldsReducer, []).flat()
}

export const getFlattenedFieldsFromScopes = (scopes, shouldFilterByRecommendation) =>
  scopes.map(scope => getModifiedFields(scope.fields, shouldFilterByRecommendation)).flat(Infinity)

const getModifiedFields = fields => {
  const updatedFields = fields.reduce((filteredFields, { isModification, ...field }) => {
    return isModification ? [filteredFields, field] : filteredFields
  }, [])

  const updatedFieldsInGroups = fields.reduce((filteredFields, field) => {
    return field.type === 'group' ? [...filteredFields, getModifiedFields(field.value)] : filteredFields
  }, [])

  return [...updatedFields, ...updatedFieldsInGroups]
}

export const getMissingFieldDescriptors = (missingFields, missingScopeDescriptor) => {
  return missingFields.map(missingField => {
    const fieldDescriptor = missingScopeDescriptor.fields.find(({ id }) => id === missingField.field_descriptor_id)
    const isTypeGroup = fieldDescriptor.type === inputTypes.GROUP

    return isTypeGroup
      ? {
        ...fieldDescriptor,
        field_descriptor_id: missingField.field_descriptor_id,
        details: {
          fields: getMissingFieldDescriptors(missingField.field_descriptors, fieldDescriptor.details)
        }
      }
      : {
        ...fieldDescriptor,
        field_descriptor_id: missingField.field_descriptor_id
      }
  })
}

const getFieldValuesTranslated = (fieldValue, fieldDescriptorValues) => {
  const fieldDescriptorValue = fieldDescriptorValues.details.values.find(
    fieldDescriptorValue => fieldDescriptorValue.value === fieldValue.value
  )

  return { value: fieldValue.value, display_text: fieldDescriptorValue.display_text }
}

const getFieldTranslated = (field, fieldDescriptors) => {
  const fieldDescriptor =
    fieldDescriptors.find(fieldDescriptor => fieldDescriptor.id === field.field_descriptor_id) ||
    fieldDescriptors.find(fieldDescriptor => fieldDescriptor.id === field.id)

  const translatedFieldProps = {
    ...field,
    name: fieldDescriptor.name,
    description: fieldDescriptor.description,
    question: fieldDescriptor.question
  }

  switch (field.type) {
    case inputTypes.GROUP:
      return {
        ...translatedFieldProps,
        details: {
          fields: field.details.fields.map(groupField => {
            return getFieldTranslated(groupField, fieldDescriptor.details.fields)
          })
        }
      }
    case inputTypes.STRING_ENUMERATION:
    case inputTypes.DYNAMIC_STRING_ENUMERATION:
    case inputTypes.DYNAMIC_MULTIPLE_STRING_ENUMERATION:
    case inputTypes.MULTIPLE_STRING_ENUMERATION:
      return {
        ...translatedFieldProps,
        details: {
          values: field.details.values.map(value => {
            return getFieldValuesTranslated(value, fieldDescriptor)
          })
        }
      }
    default:
      return translatedFieldProps
  }
}

export const getTranslatedScopes = (scopesToBeRefreshed, scopesDescriptors) => {
  return scopesToBeRefreshed.map(scope => {
    const currentScopeDescriptor =
      scopesDescriptors.find(scopesDescriptor => scopesDescriptor.id === scope.scope_descriptor_id) ||
      scopesDescriptors.find(scopesDescriptor => scopesDescriptor.id === scope.id)

    return {
      ...scope,
      name: currentScopeDescriptor.name,
      description: currentScopeDescriptor.description,
      fields: scope.fields.map(field => {
        return getFieldTranslated(field, currentScopeDescriptor.fields)
      })
    }
  })
}

function getFieldById(fields, fieldId) {
  for (const field of fields) {
    const isField = field.id === fieldId
    const isFieldGroup = field.type === inputTypes.GROUP
    if (isField) {
      return field
    }
    if (isFieldGroup) {
      const fieldInGroup = getFieldById(field.details.fields, fieldId)
      if (fieldInGroup) {
        return fieldInGroup
      }
    }
  }
}

function getFieldByTechnicalName(fields, fieldTechnicalName) {
  for (const field of fields) {
    const isField = field.technical_name === fieldTechnicalName
    const isFieldGroup = field.type === inputTypes.GROUP
    if (isField) {
      return field
    }
    if (isFieldGroup) {
      const fieldInGroup = getFieldByTechnicalName(field.details.fields, fieldTechnicalName)
      if (fieldInGroup) {
        return fieldInGroup
      }
    }
  }
}

export const getFieldByIdFromScopes = (scopes, fieldId) => {
  let field = {}

  for (const scope of scopes) {
    const fieldFound = getFieldById(scope.fields, fieldId)

    if (fieldFound) {
      field = fieldFound
      break
    }
  }

  return field
}

export const getFieldByTechnicalNameFromScopes = (scopes, fieldTechnicalName) => {
  let field = {}

  for (const scope of scopes) {
    const fieldFound = getFieldByTechnicalName(scope.fields, fieldTechnicalName)

    if (fieldFound) {
      field = fieldFound
      break
    }
  }

  return field
}

export function getStartDateTime(scopes) {
  const scheduleScope = scopes.find(scope => scope.scope_descriptor_id === scopesIDs.SCHEDULE) || { fields: [] }
  const dateFromField = scheduleScope.fields.find(field => field.field_descriptor_id === inputIDs.DATE_FROM) || {}
  const startTimeField = scheduleScope.fields.find(field => field.field_descriptor_id === inputIDs.START_TIME) || {}

  return [dateFromField.inputValue, startTimeField.inputValue]
}

export function getScopesWithoutGeometryField(scopes) {
  return scopes.map(scope => {
    return scope.scope_descriptor_id === scopesIDs.EVALUATION
      ? {
        ...scope,
        fields: scope.fields.filter(({ field_descriptor_id }) => field_descriptor_id !== inputIDs.GEOMETRY)
      }
      : scope
  })
}

const fullFillFields = (fullFields, fields) => {
  return fields.reduce((fullfiledFields, field) => {
    const existingField = fullFields.find(({ field_descriptor_id }) => field_descriptor_id === field.id)
    const formattedField = { ...field, field_descriptor_id: field.field_descriptor_id || field.id }

    if (formattedField.type === inputTypes.GROUP && existingField) {
      const groupFields = fullFillFields(formattedField.details.fields, existingField.details.fields)

      return { ...formattedField, ...existingField, details: { fields: groupFields } }
    }

    return existingField
      ? fullfiledFields.map(fullField => {
        return existingField && fullField.id === formattedField.id ? { ...fullField, ...existingField } : fullField
      })
      : fullfiledFields.concat({ ...formattedField, isExistingField: false })
  }, fullFields)
}

export const fullFillScopes = (fullScopes, scopes) => {
  if (!fullScopes.length) return scopes

  return fullScopes.map(fullScope => {
    const scope = scopes.find(({ id }) => id === fullScope.scope_descriptor_id)

    return scope ? { ...fullScope, fields: fullFillFields(fullScope.fields, scope.fields) } : fullScope
  })
}

export function getFilteredScopes(scopes, scopeIdsToFilterFields, fieldsToFilter) {
  return scopes.map(scope => {
    return scopeIdsToFilterFields.includes(scope.scope_descriptor_id)
      ? {
        ...scope,
        fields: scope.fields.filter(({ field_descriptor_id }) => !fieldsToFilter.includes(field_descriptor_id))
      }
      : scope
  })
}

export function isFieldRequired(requirement) {
  const isOptionalTypeField = requirement && requirement.type === requirementTypes.OPTIONAL
  const isConditionalTypeField = requirement && requirement.type === requirementTypes.CONDITIONAL
  const hasResultAttached = requirement && Boolean(requirement.result)
  const isResultOptional = hasResultAttached && requirement.result === requirementTypes.OPTIONAL

  if (isOptionalTypeField || isResultOptional) {
    return false
  }

  return !isConditionalTypeField || areConditionsRequired(requirement.conditions)
}

function areConditionsRequired(conditions) {
  return Boolean(conditions) && conditions.every(condition => condition.result !== requirementTypes.OPTIONAL)
}

export function removeRepeatedFields(fields) {
  return fields.reduce((fieldsNonRepeated, field) => {
    const isRepeatedField = fieldsNonRepeated.some(
      ({ field_descriptor_id: fieldId }) => fieldId === field.field_descriptor_id
    )

    return isRepeatedField ? fieldsNonRepeated : [...fieldsNonRepeated, field]
  }, [])
}

function shouldFieldBeHiddenByValue({ value, technical_name }, customConditionToHide) {
  return (
    optionalFieldsValidationFunction[technical_name] &&
    optionalFieldsValidationFunction[technical_name](value, customConditionToHide)
  )
}

export function filterFieldsToHideByValue(fields, customConditionToHide) {
  return fields.filter(field => !shouldFieldBeHiddenByValue(field, customConditionToHide))
}

export const getLateAnnouncementField = scopesToRender => {
  return getScopeFieldsByTechnicalName([inputTechnicalNames.ALLOW_LATE_ANNOUNCEMENTS], scopesToRender)[0]
}

export const addFieldToScopes = ({ fieldTechnicalName, fieldValue }, scopeId, editionScopes, scopesDescriptors) => {
  const [currentField] = getScopeFieldsByTechnicalName([fieldTechnicalName], editionScopes)
  const haveCurrentField = Boolean(currentField)

  if (haveCurrentField) return editionScopes

  const [fieldDescriptors] = getScopeFieldsByTechnicalName([fieldTechnicalName], scopesDescriptors)

  const defaultField = {
    ...fieldDescriptors,
    field_descriptor_id: fieldDescriptors.id,
    value: fieldValue
  }

  const newEditionScopes = editionScopes.map(scope => {
    if (scope.scope_descriptor_id === scopeId || scope.id === scopeId)
      return { ...scope, fields: [...scope.fields, defaultField] }

    return scope
  })

  return newEditionScopes
}
