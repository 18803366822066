import { createRulesPluginContainer } from 'modules/rules-plugin/RulesPlugin.container'

const reducerId = 'rulesPluginPreview'

const {
  rulesPluginStore,
  rulesPluginSelectors,
  rulesPluginActions,
  RulesPluginContainer
} = createRulesPluginContainer([reducerId])

export {
  rulesPluginStore as rulesPluginStorePreview,
  rulesPluginSelectors as rulesPluginSelectorsPreview,
  rulesPluginActions as rulesPluginActionsPreview,
  reducerId as rulesPreviewReducerId,
  RulesPluginContainer as RulesPluginContainerPreview
}
