import * as types from './telemetry.types'

export const initialState = {
  flights: [],
  lastUpdated: new Date().toISOString(),
  filters: [],
  isTelemetryEnabled: false,
  isTelemetryInitialized: false,
  mid: null
}

export function telemetryReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_TELEMETRY_FROM_MAP:
      return {
        ...initialState,
        isTelemetryEnabled: state.isTelemetryEnabled
      }
    case types.RECEIVE_TELEMETRY_DATA:
      return {
        ...state,
        flights: action.flights,
        lastUpdated: new Date().toISOString()
      }
    case types.FILTER_LIVE_TRAFFIC:
      return {
        ...state,
        filters: action.filters
      }
    case types.SET_IS_TELEMETRY_ENABLED:
      return {
        ...state,
        isTelemetryEnabled: action.isEnabled
      }
    case types.SET_IS_TELEMETRY_INITIALIZED:
      return {
        ...state,
        isTelemetryInitialized: action.isInitialized
      }
    case types.SET_MID_IN_USE:
      return {
        ...state,
        mid: action.mid
      }
    default:
      return state
  }
}
