export const RECEIVE_PROFILE = 'RECEIVE_PROFILE'
export const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME'
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME'
export const UPDATE_USERNAME = 'UPDATE_USERNAME'
export const SET_PROFILE_UPDATE_SUCCESS = 'SET_PROFILE_UPDATE_SUCCESS'
export const SET_PROFILE_UPDATE_ERROR = 'SET_PROFILE_UPDATE_ERROR'
export const CLEAR_PROFILE_UPDATE_ERROR = 'CLEAR_PROFILE_UPDATE_ERROR'
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
export const SET_OPEN_PHONE_VERIFICATION_MODAL = 'SET_OPEN_PHONE_VERIFICATION_MODAL'
export const SET_PHONE_NUMBER_ERROR = 'SET_PHONE_NUMBER_ERROR'
export const SET_SHOW_ENTER_SMS_CODE = 'SET_SHOW_ENTER_SMS_CODE'
export const SET_SMS_TOKEN = 'SET_SMS_TOKEN'
export const CLEAR_PHONE_VERIFICATION_MODAL_VALUES = 'CLEAR_PHONE_VERIFICATION_MODAL_VALUES'
export const SET_SMS_CODE_ERROR = 'SET_SMS_CODE_ERROR'
