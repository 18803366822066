export const SET_PRE_FLIGHT_MODE = 'SET_PRE_FLIGHT_MODE'
export const SET_IN_FLIGHT_MODE = 'SET_IN_FLIGHT_MODE'
export const RECEIVE_UPCOMING_FLIGHT = 'RECEIVE_UPCOMING_FLIGHT'
export const RECEIVE_UPCOMING_FLIGHT_ADVISORIES = 'RECEIVE_UPCOMING_FLIGHT_ADVISORIES'
export const SET_SHOW_UPCOMING_FLIGHT_ADVISORIES = 'SET_SHOW_UPCOMING_FLIGHT_ADVISORIES'
export const SET_OPEN_UPCOMING_FLIGHT_BRIEF_MODAL = 'SET_OPEN_UPCOMING_FLIGHT_BRIEF_MODAL'
export const SET_REQUESTING_END_FLIGHT = 'SET_REQUESTING_END_FLIGHT'
export const SET_END_FLIGHT_SUCCESS = 'SET_END_FLIGHT_SUCCESS'
export const SET_END_FLIGHT_ERROR = 'SET_END_FLIGHT_ERROR'
export const SET_REQUESTING_CANCEL_FLIGHT = 'SET_REQUESTING_CANCEL_FLIGHT'
export const SET_CANCEL_FLIGHT_SUCCESS = 'SET_CANCEL_FLIGHT_SUCCESS'
export const SET_CANCEL_FLIGHT_ERROR = 'SET_CANCEL_FLIGHT_ERROR'
export const CLEAR_END_FLIGHT_DIALOG = 'CLEAR_END_FLIGHT_DIALOG'
export const CLEAR_CANCEL_FLIGHT_DIALOG = 'CLEAR_CANCEL_FLIGHT_DIALOG'
export const CLEAR_UPCOMING_FLIGHT = 'CLEAR_UPCOMING_FLIGHT'
export const REQUEST_PURGE_FLIGHT = 'REQUEST_PURGE_FLIGHT'
export const SET_SHOW_END_FLIGHT_DIALOG = 'SET_SHOW_END_FLIGHT_DIALOG'
export const SET_TRAFFIC_CLIENT = 'SET_TRAFFIC_CLIENT'
export const RECEIVE_SA_MESSAGES = 'RECEIVE_SA_MESSAGES'
export const RECEIVE_ALERT_MESSAGES = 'RECEIVE_ALERT_MESSAGES'
export const SET_SELECTED_TRAFFIC = 'SET_SELECTED_TRAFFIC'
export const CLEAR_TRAFFIC = 'CLEAR_TRAFFIC'
export const SET_SNACKBAR_MESSAGE_DATA = 'SET_SNACKBAR_MESSAGE_DATA'
