import * as types from './action-types'

export const initialState = {
  previewLayerId: null,
  showAdvisories: false,
  showRulesModal: false,
  flightPlanId: '',
  showProfileModal: false,
  showAircraftModal: false,
  openVerificationRequiredAlert: false,
  openBriefModal: false,
  openWhyWereAskingModal: false,
  ruleDetails: [],
  requestingSubmitFlightPlan: false,
  submitFlightPlanError: false,
  submitFlightPlanSuccess: false,
  flightPlanOverlapError: false,
  flightAdvisories: {},
  flightAdvisoryCount: 0,
  flightAdvisoriesLastUpdated: null,
  conflictingFlights: [],
  selectedConflictingFlight: '',
  ownConflictingFlights: [],
  showUploadGeojsonModal: false,
  uploadingGeojson: false,
  rulesetIds: [],
  fetchExistingFlightPlanInProgress: true,
  requestingFlightPlan: false,
  inactiveFlightAdvisories: []
}

export function flightPlanningReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_PREVIEW_LAYER_ID:
      return {
        ...state,
        previewLayerId: action.id
      }
    case types.REMOVE_PREVIEW_LAYER_ID:
      return {
        ...state,
        previewLayerId: null
      }
    case types.SET_SHOW_ADVISORIES:
      return {
        ...state,
        showAdvisories: action.setting
      }
    case types.SET_SHOW_RULES_MODAL:
      return {
        ...state,
        showRulesModal: action.setting
      }
    case types.RECEIVE_FLIGHT_PLAN:
      return {
        ...state,
        flightPlanId: action.flightPlan.id,
        rulesetIds: action.flightPlan.rulesets || []
      }
    case types.SET_SHOW_PROFILE_MODAL:
      return {
        ...state,
        showProfileModal: action.setting
      }
    case types.SET_SHOW_AIRCRAFT_MODAL:
      return {
        ...state,
        showAircraftModal: action.setting
      }
    case types.SET_OPEN_VERIFICATION_REQUIRED_ALERT:
      return {
        ...state,
        openVerificationRequiredAlert: action.setting
      }
    case types.SET_OPEN_BRIEF_MODAL:
      return {
        ...state,
        openBriefModal: action.setting
      }
    case types.SET_OPEN_WHY_WERE_ASKING_MODAL:
      return {
        ...state,
        openWhyWereAskingModal: action.setting
      }
    case types.SET_RULE_DETAILS:
      return {
        ...state,
        ruleDetails: action.ruleDetails
      }
    case types.SET_REQUEST_SUBMIT_FLIGHT_PLAN:
      return {
        ...state,
        requestingSubmitFlightPlan: action.setting
      }
    case types.SET_SUBMIT_FLIGHT_PLAN_ERROR:
      return {
        ...state,
        submitFlightPlanError: action.setting
      }
    case types.SET_SUBMIT_FLIGHT_PLAN_SUCCESS:
      return {
        ...state,
        submitFlightPlanSuccess: action.setting
      }
    case types.SET_FLIGHT_PLAN_OVERLAP_ERROR:
      return {
        ...state,
        flightPlanOverlapError: action.setting
      }
    case types.SET_FLIGHT_ADVISORIES:
      return {
        ...state,
        flightAdvisories: action.advisories,
        flightAdvisoryCount: action.advisoryCount,
        flightAdvisoriesLastUpdated: action.lastUpdated
      }
    case types.ADD_SELECTED_CONFLICTING_FLIGHT:
      return {
        ...state,
        selectedConflictingFlight: action.flightId
      }
    case types.REMOVE_SELECTED_CONFLICTING_FLIGHT:
      return {
        ...state,
        selectedConflictingFlight: ''
      }
    case types.CLEAR_FLIGHT_PLANNING_VALUES:
      return {
        ...initialState
      }
    case types.SET_CONFLICTING_FLIGHTS:
      return {
        ...state,
        conflictingFlights: action.conflictingFlights
      }
    case types.SET_OWN_CONFLICTING_FLIGHTS:
      return {
        ...state,
        ownConflictingFlights: action.conflictingFlights
      }
    case types.SET_SHOW_UPLOAD_GEOJSON_MODAL:
      return {
        ...state,
        showUploadGeojsonModal: action.setting
      }
    case types.SET_UPLOADING_GEOJSON:
      return {
        ...state,
        uploadingGeojson: action.setting
      }
    case types.SET_FETCH_EXISTING_FLIGHT_PLAN_IN_PROGRESS: {
      return {
        ...state,
        fetchExistingFlightPlanInProgress: action.setting
      }
    }
    case types.SET_FLIGHT_PLAN_ID: {
      return {
        ...state,
        flightPlanId: action.flightPlanId
      }
    }
    case types.SET_REQUESTING_FLIGHT_PLAN: {
      return {
        ...state,
        requestingFlightPlan: action.setting
      }
    }
    case types.RECEIVE_INACTIVE_FLIGHT_ADVISORIES: {
      return {
        ...state,
        inactiveFlightAdvisories: action.inactiveFlightAdvisories
      }
    }
    default:
      return state
  }
}
