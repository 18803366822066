export const SET_FLIGHT_WEATHER = 'SET_FLIGHT_WEATHER'
export const WEATHER_REQUEST_IN_PROGRESS = 'WEATHER_REQUEST_IN_PROGRESS'
export const SET_FLIGHT_LOCATION = 'SET_FLIGHT_LOCATION'
export const SET_FLIGHT_BRIEF = 'SET_FLIGHT_BRIEF'
export const SET_REQUESTING_BRIEF = 'SET_REQUESTING_BRIEF'
export const SET_ERROR_DOWNLOADING_AUTHORIZATION_PDF = 'SET_ERROR_DOWNLOADING_AUTHORIZATION_PDF'
export const SET_FLIGHT_BRIEF_ERROR = 'SET_FLIGHT_BRIEF_ERROR'
export const SET_OPEN_VIOLATING_RULES_ALERT = 'SET_OPEN_VIOLATING_RULES_ALERT'
export const SET_SHOW_LAANC_MODAL = 'SET_SHOW_LAANC_MODAL'
export const SET_HAS_AGREED_LAANC_RULES = 'SET_HAS_AGREED_LAANC_RULES'
export const SET_HAS_AGREED_VIOLATING_RULES = 'SET_HAS_AGREED_VIOLATING_RULES'
export const SET_AGREEMENT_ID = 'SET_AGREEMENT_ID'
