import { AirmapWebAppWhiteLabelConfig, airmapWebAppWhiteLabelConfig } from "./web-app/airmap.web-app.config";
import { AirmapSfoWhiteLabelConfig, airmapSfoWhiteLabelConfig } from "./sfo/airmap.sfo.config";

type AirmapConfigMap = {
  webApp: AirmapWebAppWhiteLabelConfig,
  sfo: AirmapSfoWhiteLabelConfig,
}

export const airmapConfigMap: AirmapConfigMap = {
  webApp: airmapWebAppWhiteLabelConfig,
  sfo: airmapSfoWhiteLabelConfig,
}
