import * as types from './action-types'

export const initialState = {
  profile: {},
  firstName: '',
  lastName: '',
  username: '',
  pilotId: '',
  profileUpdateSuccess: false,
  phoneNumber: '',
  openPhoneVerificationModal: false,
  phoneNumberError: false,
  showEnterSMSCode: false,
  smsToken: '',
  phoneNumberErrorMsg: '',
  SMSCodeError: false,
  SMSCodeErrorMsg: '',
  errorType: '',
  errorMsg: ''
}

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.data
        },
        firstName: action.data.first_name || state.firstName,
        lastName: action.data.last_name || state.lastName,
        username: action.data.username || state.username,
        pilotId: action.data.id
      }
    case types.SET_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        profileUpdateSuccess: action.setting
      }
    case types.UPDATE_FIRST_NAME:
      return {
        ...state,
        firstName: action.value
      }
    case types.UPDATE_LAST_NAME:
      return {
        ...state,
        lastName: action.value
      }
    case types.UPDATE_USERNAME:
      return {
        ...state,
        username: action.value
      }
    case types.SET_PROFILE_UPDATE_ERROR:
      return {
        ...state,
        errorType: action.errorType,
        errorMsg: action.errorMsg
      }
    case types.CLEAR_PROFILE_UPDATE_ERROR:
      return {
        ...state,
        errorType: action.errorType,
        errorMsg: action.errorMsg
      }
    case types.SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.phoneNumber
      }
    case types.SET_OPEN_PHONE_VERIFICATION_MODAL:
      return {
        ...state,
        openPhoneVerificationModal: action.setting
      }
    case types.SET_PHONE_NUMBER_ERROR:
      return {
        ...state,
        phoneNumberError: action.setting,
        phoneNumberErrorMsg: action.errorMsg
      }
    case types.SET_SHOW_ENTER_SMS_CODE:
      return {
        ...state,
        showEnterSMSCode: action.setting
      }
    case types.SET_SMS_TOKEN:
      return {
        ...state,
        smsToken: action.value
      }
    case types.CLEAR_PHONE_VERIFICATION_MODAL_VALUES:
      return {
        ...state,
        smsToken: '',
        showEnterSMSCode: false,
        phoneNumberError: false,
        phoneNumber: '',
        phoneNumberErrorMsg: '',
        SMSCodeError: false,
        SMSCodeErrorMsg: ''
      }
    case types.SET_SMS_CODE_ERROR:
      return {
        ...state,
        SMSCodeError: action.setting,
        SMSCodeErrorMsg: action.errorMsg
      }
    default:
      return state
  }
}
