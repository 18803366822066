export const SET_REQUESTING_FLIGHTS = 'SET_REQUESTING_FLIGHTS'
export const REQUEST_FLIGHTS_ERROR = 'REQUEST_FLIGHTS_ERROR'
export const RECEIVE_FLIGHTS = 'RECEIVE_FLIGHTS'
export const SET_OPEN_FLIGHT_MENU = 'SET_OPEN_FLIGHT_MENU'
export const SET_MENU_ANCHOR_EL = 'SET_MENU_ANCHOR_EL'
export const SET_SELECTED_FLIGHTS_MODAL_FLIGHT = 'SET_SELECTED_FLIGHTS_MODAL_FLIGHT'
export const SET_OPEN_END_FLIGHT_DIALOG = 'SET_OPEN_END_FLIGHT_DIALOG'
export const SET_OPEN_CANCEL_FLIGHT_DIALOG = 'SET_OPEN_CANCEL_FLIGHT_DIALOG'
export const FILTER_ENDED_FLIGHT = 'FILTER_ENDED_FLIGHT'
export const SET_REQUEST_FILTER_FLIGHT = 'SET_REQUEST_FILTER_FLIGHT'
export const SET_OPEN_DETAIL_DIALOG = 'SET_OPEN_DETAIL_DIALOG'
export const SET_OPEN_FLIGHT_DETAIL_BRIEF = 'SET_OPEN_FLIGHT_DETAIL_BRIEF'
export const SET_OPEN_DELETE_FLIGHT_DIALOG = 'SET_OPEN_DELETE_FLIGHT_DIALOG'
export const SET_DELETE_FLIGHT_ERROR = 'SET_DELETE_FLIGHT_ERROR'
export const DELETE_FLIGHT_SUCCESS = 'DELETE_FLIGHT_SUCCESS'
export const REQUEST_DUPLICATE_FLIGHT = 'REQUEST_DUPLICATE_FLIGHT'
export const SET_DUPLICATE_FLIGHT_SUCCESS = 'SET_DUPLICATE_FLIGHT_SUCCESS'
export const SET_DUPLICATE_FLIGHT_ERROR = 'SET_DUPLICATE_FLIGHT_ERROR'
export const CLEAR_DUPLICATE_FLIGHT_DIALOG = 'CLEAR_DUPLICATE_FLIGHT_DIALOG'
