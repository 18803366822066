import * as types from './action-types'
import { DRAW_POINT } from '../../components/FlightPlanning/constants'
import settings from 'settings'

const {
  webAppWhiteLabelConfig: { MapSettings }
} = settings

const initialState = {
  bufferValue: 10,
  drawMapLoaded: false,
  showConfirmDeleteDrawingDialog: false,
  deleteDrawing: false,
  deleteAllDrawing: false,
  drawMode: DRAW_POINT,
  mapCenter: { ...MapSettings.defaultLocation },
  nonBufferedFlightPlanGeometry: {},
  flightPlanGeometry: {},
  flightPlanDrawingId: '',
  requestDeleteFlightPlan: false,
  flightPlanColor: 'grey',
  flightPlanningError: false,
  requestDeleteLocalStorage: false,
  drawModeSetManually: false,
  requestingReCenterDrawing: false
}

export function drawingReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_BUFFER_VALUE:
      return {
        ...state,
        bufferValue: action.bufferValue
      }
    case types.SET_DRAW_MAP_LOADED:
      return {
        ...state,
        drawMapLoaded: true
      }
    case types.REQUEST_DELETE_DRAWING:
      return {
        ...state,
        deleteDrawing: true
      }
    case types.REQUEST_DELETE_CONFIRMATION_DIALOG:
      return {
        ...state,
        showConfirmDeleteDrawingDialog: true
      }
    case types.CANCEL_REQUEST_DELETE_DRAWING:
      return {
        ...state,
        showConfirmDeleteDrawingDialog: false
      }
    case types.CONFIRM_REQUEST_DELETE_DRAWING:
      return {
        ...state,
        showConfirmDeleteDrawingDialog: false,
        deleteAllDrawing: true
      }
    case types.CLEAR_REQUEST_DELETE_DRAWING:
      return {
        ...state,
        deleteDrawing: false,
        deleteAllDrawing: false
      }
    case types.SET_DRAW_MODE:
      return {
        ...state,
        drawMode: action.mode,
        drawModeSetManually: false
      }
    case types.MANUALLY_SET_DRAW_MODE:
      return {
        ...state,
        drawMode: action.mode,
        drawModeSetManually: true
      }
    case types.SET_CENTER_COORDINATES: {
      return {
        ...state,
        mapCenter: action.mapCenter
      }
    }
    case types.SET_FLIGHT_PLAN_LAYER_ID: {
      return {
        ...state,
        flightPlanDrawingId: action.id
      }
    }
    case types.SET_FLIGHT_PLAN_COLOR: {
      return {
        ...state,
        flightPlanColor: action.flightPlanColor
      }
    }
    case types.SET_FLIGHT_PLAN_ERROR: {
      return {
        ...state,
        flightPlanningError: action.flightPlanningError
      }
    }
    case types.ADD_NON_BUFFERED_FEATURE: {
      return {
        ...state,
        nonBufferedFlightPlanGeometry: action.nonBufferedFeature
      }
    }
    case types.DELETE_FLIGHT_PLAN_GEOMETRY: {
      return {
        ...state,
        flightPlanGeometry: {}
      }
    }
    case types.DELETE_FLIGHT_PLAN_LAYER_ID: {
      return {
        ...state,
        flightPlanDrawingId: '',
        requestDeleteFlightPlan: !state.requestDeleteFlightPlan,
        nonBufferedFlightPlanGeometry: {},
        flightPlanColor: 'grey',
        flightPlanGeometry: {}
      }
    }
    case types.ADD_NEW_FLIGHT_PLAN_GEOMETRY:
      return {
        ...state,
        flightPlanGeometry: action.geometry
      }
    case types.SET_REQUEST_DELETE_LOCAL_STORAGE:
      return {
        ...state,
        requestDeleteLocalStorage: action.setting
      }
    case types.CLEAR_ALL_DRAWING_VALUES:
      return {
        ...initialState
      }
    case types.SET_REQUESTING_RE_CENTER_DRAWING:
      return {
        ...state,
        requestingReCenterDrawing: action.setting
      }
    default:
      return {
        ...state
      }
  }
}
