import { SkyguideSfoWhiteLabelConfig, skyguideSfoWhiteLabelConfig } from "./sfo/skyguide.sfo.config";
import { SkyguideWebAppWhiteLabelConfig, skyguideWebAppWhiteLabelConfig } from "./web-app/skyguide.web-app.config";

type SkyguideConfigMap = {
  webApp: SkyguideWebAppWhiteLabelConfig,
  sfo: SkyguideSfoWhiteLabelConfig
}

export const skyguideConfigMap: SkyguideConfigMap = {
  webApp: skyguideWebAppWhiteLabelConfig,
  sfo: skyguideSfoWhiteLabelConfig
}
