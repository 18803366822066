// import PulseTracker from '../../monorepo/packages/airmap-pulse/src'

import Auth from './Auth'
import { colors, mapboxGeocoderStyles } from './constants'
import config from './config'
import { webAppWhiteLabelConfig, sfoWhiteLabelConfig } from './whiteLabel'
import FirebaseAnalytics from 'projects/FirebaseAnalytics'
import { NODE_ENV, FILE_EXTENSIONS_SUPPORTED } from "@env"
import { AirmapWebAppWhiteLabelConfig } from "./whiteLabel/airmap/web-app/airmap.web-app.config";
import { AirmapSfoWhiteLabelConfig } from "./whiteLabel/airmap/sfo/airmap.sfo.config";
import { Config } from "./config";

const env = NODE_ENV || 'development'
const extensionsAllowed = FILE_EXTENSIONS_SUPPORTED || 'image/jpeg,image/png,application/pdf'

type ColorsKeys = keyof typeof colors
type ColorsValues = typeof colors[ColorsKeys]

type Settings = Config & {
  AirMapAuth: Auth,
  colors: { [key in ColorsKeys]: ColorsValues},
  env: string,
  webAppWhiteLabelConfig: AirmapWebAppWhiteLabelConfig,
  sfoWhiteLabelConfig: AirmapSfoWhiteLabelConfig,
  geoPathPrefix: string,
  mapboxGeocoderStyles: {url: string},
  firebaseAnalytics: FirebaseAnalytics,
  extensionsAllowed: string
}

const settings: Settings = {
  ...config,
  AirMapAuth: new Auth(config),
  colors,
  env,
  webAppWhiteLabelConfig,
  sfoWhiteLabelConfig,
  geoPathPrefix: '/geo',
  mapboxGeocoderStyles,
  firebaseAnalytics: new FirebaseAnalytics(),
  extensionsAllowed
}

export default settings
