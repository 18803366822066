export const SET_TEMPLATE_MAP_INSTANCE = 'SET_TEMPLATE_MAP_INSTANCE'
export const SET_TEMPLATE_RULESETS = 'SET_TEMPLATE_RULESETS'
export const RECEIVE_TEMPLATE_MAP_STYLES = 'RECEIVE_TEMPLATE_MAP_STYLES'
export const RECEIVE_TEMPLATE_ADVISORIES = 'RECEIVE_TEMPLATE_ADVISORIES'
export const TOGGLE_RULES_MODAL = 'TOGGLE_RULES_MODAL'
export const VIEW_RULESET_INFO = 'VIEW_RULESET_INFO'
export const HIDE_RULESET_INFO = 'HIDE_RULESET_INFO'
export const UNMOUNT_MAP_PURGE_STORE = 'UNMOUNT_MAP_PURGE_STORE'
export const SET_TEMPLATE_GEOMETRY = 'SET_TEMPLATE_GEOMETRY'
export const MANUALLY_SET_TEMPLATE_DRAW_MODE = 'MANUALLY_SET_TEMPLATE_DRAW_MODE'
export const SET_TEMPLATE_COLOR = 'SET_TEMPLATE_COLOR'
export const SET_TEMPLATE_DRAWING_ID = 'SET_TEMPLATE_DRAWING_ID'
export const SET_SHOW_CONFIRM_DELETE_DRAWING_DIALOG = 'SET_SHOW_CONFIRM_DELETE_DRAWING_DIALOG'
export const SET_TEMPLATE_BUFFER = 'SET_TEMPLATE_BUFFER'
export const SET_CONFIRM_DELETE_ENTIRE_TEMPLATE_DRAWING = 'SET_CONFIRM_DELETE_ENTIRE_TEMPLATE_DRAWING'
export const SET_TEMPLATE_GEOMETRY_INTERSECTION_ERROR = 'SET_TEMPLATE_GEOMETRY_INTERSECTION_ERROR'
export const SET_SHOW_UPLOAD_TEMPLATE_GEOJSON_MODAL = 'SET_SHOW_UPLOAD_TEMPLATE_GEOJSON_MODAL'
export const SET_UPLOADING_TEMPLATE_GEOJSON = 'SET_UPLOADING_TEMPLATE_GEOJSON'
export const SET_TEMPLATE_MAP_ZOOM = 'SET_TEMPLATE_MAP_ZOOM'
export const SET_SCALE_CONTROL = 'SET_SCALE_CONTROL'
export const SET_RAW_FEATURE_GEOMETRY = 'SET_RAW_FEATURE_GEOMETRY'
export const REBUILD_MAP = 'REBUILD_MAP'
export const CLEAN_MAP_TEMPLATE_GEOMETRY = 'CLEAN_MAP_TEMPLATE_GEOMETRY'
export const SET_UPLOAD_GEOJSON_FEATURES = 'SET_UPLOAD_GEOJSON_FEATURES'
export const TOGGLE_LAYERS_MENU_STATUS = 'TOGGLE_LAYERS_MENU_STATUS'
export const OPEN_OPERATION_GEOMETRY_LIST_POP_UP = 'OPEN_OPERATION_GEOMETRY_LIST_POP_UP'
export const CLOSE_OPERATION_GEOMETRY_LIST_POP_UP = 'CLOSE_OPERATION_GEOMETRY_LIST_POP_UP'
export const SET_OPERATION_GEOMETRY_LIST_POP_UP_INFO = 'SET_OPERATION_GEOMETRY_LIST_POP_UP_INFO'
export const SET_OPERATION_GEOMETRY_LIST_POP_UP_COORDINATES = 'SET_OPERATION_GEOMETRY_LIST_POP_UP_COORDINATES'
export const OPEN_OPERATION_GEOMETRY_POP_UP = 'OPEN_OPERATION_GEOMETRY_POP_UP'
export const CLOSE_OPERATION_GEOMETRY_POP_UP = 'CLOSE_OPERATION_GEOMETRY_POP_UP'
export const SET_OPERATION_GEOMETRY_POP_UP_INFO = 'SET_OPERATION_GEOMETRY_POP_UP_INFO'
export const SET_OPERATION_GEOMETRY_COORDINATES = 'SET_OPERATION_GEOMETRY_COORDINATES'
export const SET_EXPERIMENTAL_AIRCRAFT_TYPE_FILTER = 'SET_EXPERIMENTAL_AIRCRAFT_TYPE_FILTER'
export const ENABLE_EXPERIMENTAL_FILTERS = 'ENABLE_EXPERIMENTAL_FILTERS'
export const DISABLE_EXPERIMENTAL_FILTERS = 'DISABLE_EXPERIMENTAL_FILTERS'
export const SET_RULESETS_IDS = 'SET_RULESETS_IDS'
export const SHOW_CLICKED_ADVISORIES_BOX = 'SHOW_CLICKED_ADVISORIES_BOX'
export const IS_EDITION_MODE_ENABLED = 'IS_EDITION_MODE_ENABLED'
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS'
export const SET_HIGHLIGHTED_GEOMETRY_ID = 'SET_HIGHLIGHTED_GEOMETRY_ID'
export const SET_HIGHLIGHTED_CONFLICT_ID = 'SET_HIGHLIGHTED_CONFLICT_ID'
