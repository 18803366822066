import { homeReducer } from './pages/Home/reducer'
import { aircraftReducer } from './pages/AircraftModal/reducer'
import { profileReducer } from './pages/ProfileModal/reducer'
import { drawingReducer } from './pages/GeometryDrawingMapSection/reducer'
import { flightModeReducer } from './pages/FlightModeSection/reducer'
import { flightsModalReducer } from './pages/FlightsModal/reducer'
import { briefModalReducer } from './pages/BriefModal/reducer'
import { detailFormReducer } from './pages/DetailForm/reducer'
import { flightPlanningReducer } from './pages/FlightPlanning/reducer'

import { telemetryReducer } from './modules/telemetry/store/telemetry.reducer'

export const webAppReducers = (routerReducer) => ({
  home: homeReducer,
  flightPlanning: flightPlanningReducer,
  router: routerReducer,
  aircraft: aircraftReducer,
  profile: profileReducer,
  drawing: drawingReducer,
  flightMode: flightModeReducer,
  flightsModal: flightsModalReducer,
  brief: briefModalReducer,
  detailForm: detailFormReducer,
  telemetry: telemetryReducer
})
