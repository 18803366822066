import { appVersion } from '../../../app-version.js'
import { AirmapLogo } from '../shared/airmap.logo'
import { AirmapPrivacyPolicy } from '../shared/airmap.privacy-policy'
import { TextLink, TextLinkWording } from "../shared/config.types";
import { ReactNode } from "react";

export type AirmapSfoWhiteLabelConfig = {
  defaultUserClient: string[],
  Settings: {
    SocialMedia: TextLinkWording,
    AppVersion: TextLink,
    Faq: TextLinkWording,
    PrivacyPolicy: TextLinkWording,
    TermsAndConditions: TextLinkWording,
    CompanyName: string,
    SupportCompanyName: string,
    SupportCompanyContact: string
  },
  PolicyContent: () => ReactNode,
  Logo: () => ReactNode,
  MapSettings: {
    geocoderBoundingBox: []
  }
}

export const airmapSfoWhiteLabelConfig: AirmapSfoWhiteLabelConfig = {
  defaultUserClient: ['dsna'],
  Settings: {
    SocialMedia: {
      wording: 'socialMedia',
      Text: 'Follow @AirMapIO',
      Link: 'https://twitter.com/AirMapIO'
    },
    AppVersion: appVersion,
    Faq: {
      wording: 'faq',
      Text: 'FAQ',
      Link: 'https://support.airmap.com/hc/en-us'
    },
    PrivacyPolicy: {
      wording: 'privacyPolicy',
      Text: 'Privacy Policy',
      Link: 'https://www.airmap.com/privacy-policy/'
    },
    TermsAndConditions: {
      wording: 'termsAndConditions',
      Text: 'Terms and Conditions',
      Link: 'https://www.airmap.com/terms-service/'
    },
    CompanyName: 'DSNA/SNA-SE',
    SupportCompanyName: 'Airmap',
    SupportCompanyContact: 'support@airmap.com'
  },
  PolicyContent: AirmapPrivacyPolicy,
  Logo: AirmapLogo,
  MapSettings: {
    geocoderBoundingBox: []
  }
}
