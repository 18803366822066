import * as types from './action-types'

export const initialState = {
  currentFlights: [],
  futureFlights: [],
  pastFlights: [],
  requestingFlights: false,
  requestingFlightsError: false,
  selectedFlight: '',
  menuAnchorEl: null,
  openFlightMenu: '',
  openEndFlightDialog: false,
  openCancelFlightDialog: false,
  requestFilterFlight: false,
  openDetailDialog: false,
  openBriefModal: false,
  openDeleteFlightDialog: false,
  deleteFlightError: false,
  requestDuplicateFlight: false,
  duplicateFlightSuccess: false,
  duplicateFlightError: false,
  openDuplicateFlightModal: false
}

export function flightsModalReducer(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_FLIGHTS: {
      return {
        ...state,
        currentFlights: action.currentFlights,
        futureFlights: action.futureFlights,
        pastFlights: action.pastFlights
      }
    }
    case types.SET_REQUESTING_FLIGHTS: {
      return {
        ...state,
        requestingFlights: action.setting
      }
    }
    case types.REQUEST_FLIGHTS_ERROR: {
      return {
        ...state,
        requestingFlightsError: action.setting
      }
    }
    case types.SET_OPEN_FLIGHT_MENU: {
      return {
        ...state,
        openFlightMenu: action.flightId
      }
    }
    case types.SET_MENU_ANCHOR_EL: {
      return {
        ...state,
        menuAnchorEl: action.anchorEl
      }
    }
    case types.SET_SELECTED_FLIGHTS_MODAL_FLIGHT: {
      return {
        ...state,
        selectedFlight: action.flightId
      }
    }
    case types.SET_OPEN_END_FLIGHT_DIALOG: {
      return {
        ...state,
        openEndFlightDialog: action.setting
      }
    }
    case types.SET_OPEN_CANCEL_FLIGHT_DIALOG: {
      return {
        ...state,
        openCancelFlightDialog: action.setting
      }
    }
    case types.SET_REQUEST_FILTER_FLIGHT: {
      return {
        ...state,
        requestFilterFlight: action.setting
      }
    }
    case types.FILTER_ENDED_FLIGHT: {
      return {
        ...state,
        currentFlights: filterOutFlight(state.currentFlights, action.flightId),
        futureFlights: filterOutFlight(state.futureFlights, action.flightId)
      }
    }
    case types.SET_OPEN_DETAIL_DIALOG:
      return {
        ...state,
        openDetailDialog: action.setting
      }
    case types.SET_OPEN_FLIGHT_DETAIL_BRIEF:
      return {
        ...state,
        openBriefModal: action.setting
      }
    case types.SET_OPEN_DELETE_FLIGHT_DIALOG:
      return {
        ...state,
        openDeleteFlightDialog: action.setting
      }
    case types.DELETE_FLIGHT_SUCCESS:
      return {
        ...state,
        pastFlights: filterOutFlight(state.pastFlights, action.flightId)
      }
    case types.SET_DELETE_FLIGHT_ERROR:
      return {
        ...state,
        deleteFlightError: action.setting
      }
    case types.REQUEST_DUPLICATE_FLIGHT:
      return {
        ...state,
        requestDuplicateFlight: action.setting
      }
    case types.SET_DUPLICATE_FLIGHT_SUCCESS:
      return {
        ...state,
        duplicateFlightSuccess: action.setting
      }
    case types.SET_DUPLICATE_FLIGHT_ERROR:
      return {
        ...state,
        duplicateFlightError: action.setting
      }
    case types.CLEAR_DUPLICATE_FLIGHT_DIALOG:
      return {
        ...state,
        requestDuplicateFlight: false,
        duplicateFlightSuccess: false,
        duplicateFlightError: false
      }
    default:
      return state
  }
}

const filterOutFlight = (flightsArray, selectedFlight) => flightsArray.filter(flight => flight.id !== selectedFlight)
